import { Box } from "@mui/material";
import React, { useState } from "react";
import ResponsiveDrawer from "../sidebar";
import { useAuth } from "../../context/authContext";
import { useLocation } from "react-router-dom";
import FeatureCard from "../features/FeatureCard";
import DashbordFeatures from "../commonComponents/UnsubscribedFeatures";
import Adminsidebar from "../adminsidebar";
import AdminHeader from "../adminheader";

function DashBoardLayout({ children }) {
  const { isAuthenticate, isUserSubscribed } = useAuth();
  const location = useLocation();
  const { pathname } = location;
  const [ sidebar , setSidebar ] = useState(false);

  return (
    <>
      <div className="flex bg-bglight tab:flex-wrap">
        <div className="w-[280px] tab:w-full tab:hidden">
          <Adminsidebar sidebar={sidebar} setSidebar={setSidebar}/>
        </div>
        <div className="w-[calc(100%-280px)] tab:w-full">
          <AdminHeader />
          <div className="p-5 tab:min-h-[calc(100vh-73px)]">
            {/* {children} */}

            {!isUserSubscribed &&
            !pathname.includes("profile") &&
            !pathname.includes("help") &&
            !pathname.includes("privacy-policy") &&
            !pathname.includes("terms-of-service")  &&
            !pathname.includes("/")  ? (
              <div className="unsubscribedContainerWrapper">
                <div className="unsubscribedContainer">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "18px",
                    }}
                  >
                    <img src="/assets/lockgif.gif" alt="lock" className="lockimage" style={{ width: "140px", height: "140px" }} />
                    <h6 className="unsubscribedHeading">
                      This Feature is <br /> locked in Free Plan
                    </h6>
                    <p className="unsubscribedpara">
                      Please, Upgrade your plan to <br /> enjoy full benefits
                    </p>
                  </div>
                  <FeatureCard feature={DashbordFeatures} />
                </div>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
      {/* <Box sx={{ display: "flex" }} className="pagewrapper">
      <ResponsiveDrawer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${240}px)` },
          marginTop: {
            xs: "60px",
            md: "60px",
          },
          padding: "20px",
          background: "#f2f2f2",
        }}
      >
        {!isUserSubscribed &&
        isAuthenticate && 
        !pathname.includes("profile") &&
        !pathname.includes("help") &&
        !pathname.includes("privacy-policy") &&
        !pathname.includes("terms-of-service") ? (
          <div className="unsubscribedContainerWrapper">
            <div className="unsubscribedContainer">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                }}
              >
                <img
                  src="/assets/lockgif.gif"
                  alt="lock"
                  className="lockimage"
                  style={{ width: "140px", height: "140px" }}
                />
                <h6 className="unsubscribedHeading">
                  This Feature is <br /> locked in Free Plan
                </h6>
                <p className="unsubscribedpara">
                  Please, Upgrade your plan to <br /> enjoy full benefits
                </p>
              </div>
              <FeatureCard feature={DashbordFeatures} />
            </div>
          </div>
        ) : (
          children
        )}
      </Box>
    </Box> */}
    </>
  );
}

export default DashBoardLayout;
