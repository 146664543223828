import React from 'react'
import CheckIcon from '../../../svg/CheckIcon'

const FeaturesBanner = () => {
    return (
        <div className='py-60 bg-primary mobile:py-60'>
            <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
                <div className='grid grid-cols-[1fr_380px] mobile:grid-cols-1 mobile:gap-10 gap-120 items-center'>
                    <div>
                        <h1 className='text-[64px] leading-[76px] text-black300 font-bold mb-8 mobile:text-[32px] mobile:leading-normal'>
                            Simple Plans,<br /> No Hidden Costs
                        </h1>
                        <p className='text-xl mobile:text-base tracking-normal text-black300 max-w-[600px] '>
                            Our service acts as your personal virtual assistant, seamlessly keeping tabs on every recurring payment in one streamlined location.
                        </p>
                    </div>
                    <div>
                        <div className=' flex flex-col justify-between  p-6 bg-white rounded-xl border border-solid border-borderColor1'>
                            <div>
                                <div className='pb-[14px] border-solid border-b border-borderColor1 flex items-center justify-between'>
                                    <p className='text-lg font-extrabold text-black200'>
                                        Premium
                                    </p>
                                    <p className='text-base  text-black200 font-semibold'>
                                        30 Days Free
                                    </p>
                                </div>
                                <div className='py-3 flex items-center gap-9 border-b border-solid border-borderColor1'>
                                    <div>
                                        {/* <p className='text-sm text-gray800 font-normal'>
                            $7.99/ m
                            </p> */}
                                        <p className='text-xl font-extrabold text-black200'>
                                            $9.99 <span className='text-sm'>p/m</span>
                                        </p>
                                    </div>
                                    <p className='text-sm text-gray800 font-normal leading-normal'>
                                        Billed Monthly
                                    </p>
                                </div>

                                <div className='py-4'>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Basic Tracking
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Calendar View
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Recommended Subs
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Price Match
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Extension
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Projected Spending
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Alladdin DB
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Alerts (In App + Email)
                                        </span>
                                    </div>
                                    <div className='flex items-center gap-2 pb-3'>
                                        <CheckIcon />
                                        <span className='block text-sm font-semibold text-black200'>
                                            Advanced Reporting
                                        </span>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <button className='py-3 px-3 min-w-[210px] bg-primary rounded-full text-base font-medium'>
                                        Sign in
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default FeaturesBanner
