import React, { createContext, useState, useEffect, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import * as authApi from "../redux/api/auth";
import AppConstants from "../constants/AppConstants";

const AuthContext = createContext();

export const getUser = async () => {
  try {
    const response = await axios.get(`${AppConstants.baseURL}/auth/profile`, {
      headers:{
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("authToken"),
        "ngrok-skip-browser-warning": "69420"
      }
      // withCredentials: true,
    });
    if (response?.data?.success) {
      return {
        status: "SIGNED_IN",
        user: response?.data?.payload,
        isUserSubscribed: response?.data?.payload?.isUserSubscribed,
       
      };
    } else {
      return { status: "SIGNED_OUT", user: null, isUserSubscribed: false };
    }
  } catch (error) {
    return { status: "SIGNED_OUT", user: null };
  }
};

export const AuthProvider = (props) => {
  const navigate = useNavigate();
  const { myAuth = { status: "SIGNED_OUT", user: null } } = props;
  const [users, setUsers] = useState(false);
  const [userSubscribed, setUserSubscribed] = useState(false);


  const login = useCallback(async (formData) => {
    try {
      const resp = await axios({
        method: "post",
        url: `${AppConstants.baseURL}/auth/login`,
        data: formData,
        // withCredentials: true,
      });
      localStorage.setItem("authToken",resp?.data?.payload?.token )          
      if (resp?.data?.success) {
        const data = await axios.get(`${AppConstants.baseURL}/auth/profile`, {
          headers:{
            "Content-Type": "application/json",
            "x-auth-token": resp?.data?.payload?.token,
            "ngrok-skip-browser-warning": "69420"
          }
        })
          message.success(resp.data.message || "Logged In successfully");
          setUsers(data?.data?.payload);
          setUserSubscribed(data.data.isUserSubscribed);
      } else {
        message.error(resp.data.message || "Something Went Wrong");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }, []);

  const sendOTP = useCallback(async (formData) => {
    try {
      const resp = await axios({
        method: "post",
        url: `${AppConstants.baseURL}/auth/sendotp`,
        data: formData,
        // withCredentials: true,
      });
          
      if (resp?.data?.success) {
          message.success(resp?.data?.messages || "OTP send successfully");
      } else {
        message.error(resp.data.message || "Something Went Wrong");
      }
      return resp
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }, []);

  const verifyOTP = useCallback(async (formData) => {
    try {
      const resp = await axios({
        method: "post",
        url: `${AppConstants.baseURL}/auth/verifyotp`,
        data: formData,
        // withCredentials: true,
      });
      if (resp?.data?.success) {
          message.success(resp?.data?.messages || "OTP send successfully");
      } else {
        message.error(resp.data.message || "Something Went Wrong");
      }
      return resp
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }, []);

  const savePassword = useCallback(async (formData) => {
    try {
      const resp = await axios({
        method: "post",
        url: `${AppConstants.baseURL}/auth/forgot`,
        data: formData,
        // withCredentials: true,
      });
      if (resp?.data?.success) {
          message.success(resp?.data?.messages || "OTP send successfully");
      } else {
        message.error(resp.data.message || "Something Went Wrong");
      }
      return resp
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }, []);

  const signupUser = useCallback(async (signupData) => {
    try {
      const resp = await axios({
        method: "post",
        url: `${AppConstants.baseURL}/auth/signup`,
        data: signupData,
        // withCredentials: true,
      });
      localStorage.setItem("authToken",resp?.data?.payload?.token )
      if (resp?.data?.success) {
        const data = await axios.get(`${AppConstants.baseURL}/auth/profile`, {
          headers:{
            "Content-Type": "application/json",
            "x-auth-token": resp?.data?.payload?.token,
            "ngrok-skip-browser-warning": "69420"
          }
        })
        message.success(resp.data.message || "Registration successful");
        setUsers(data?.data?.payload);
        navigate("/dashboard");
      } else {
        message.error(resp.data.message || "Something Went Wrong");
      }
    } catch (error) {
      message.error(error.message);
    }
  }, []);

  const logout = useCallback(async () => {
    // const history = window.location.href?.split("/")?.slice(3)?.join("/");
    // localStorage.setItem("history", history);
    try {
      const resp = await axios.post(`${AppConstants.baseURL}/auth/logout`, {
        // withCredentials: true,
      });
      if (resp.data.code === 200) {
        // message.success(resp.data.message || "Logout successful");
        setUsers(false);
        console.log("Successfully logged out")
      } else {
        console.log("Error logging out")
        // message.error(resp.data.message || "Error logging out");
      }
    } catch (error) {
      console.log("Error logging out")
      // message.error(error.message || "Something Went Wrong");
    }
  });

  // useEffect(() => {
  //     const fetchUser = async () => {
  //         const { user } = await getUser();
  //         setUsers(user);
  //     };
  //     fetchUser();
  // }, []);
  const isAuthenticate = !!users || !!myAuth.user;
  const isUserSubscribed = !!userSubscribed || !!myAuth.isUserSubscribed;
  // const isUserSubscribed = true;

  const userData = users || myAuth.user;
  const authContextValue = {
    isAuthenticate,
    isUserSubscribed,
    userData,
    logout,
    login,
    signupUser,
    sendOTP,
    verifyOTP,
    savePassword,
  };

  return <AuthContext.Provider value={authContextValue} {...props} />;
};

export const useAuth = () => React.useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;

export const PrivateRoutes = () => {
  const { isAuthenticate, isUserSubscribed } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticate || isAuthenticate === false) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticate, navigate]);

  return <Outlet />;
};

export const LoginRoutes = () => {
  const { isAuthenticate } = useAuth();
  const navigate = useNavigate();

  const history = localStorage.getItem("history");

  useEffect(() => {
    if (isAuthenticate) {
      // if (history || history === null || history === undefined) navigate(`/dashboard`, { replace: true, });
      // else navigate(`/${history}`, { replace: true, })
      navigate(`/dashboard`, { replace: true });
    }
  }, [isAuthenticate, navigate]);

  return <Outlet />;
};
