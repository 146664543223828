import React from 'react'

const FacebookIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <g clip-path="url(#clip0_1385_1339)">
                <path d="M20.001 10.2002C20.001 15.191 16.3393 19.3285 11.5593 20.0794V13.1102H13.8835L14.326 10.2269H11.5593V8.35603C11.5593 7.56686 11.946 6.79853 13.1843 6.79853H14.4418V4.34353C14.4418 4.34353 13.3001 4.14853 12.2093 4.14853C9.93098 4.14853 8.44264 5.52936 8.44264 8.02853V10.226H5.91014V13.1094H8.44264V20.0785C3.66348 19.3269 0.000976562 15.1902 0.000976562 10.2002C0.000976562 4.6777 4.47848 0.200195 10.001 0.200195C15.5235 0.200195 20.001 4.67686 20.001 10.2002Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1385_1339">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.139648)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FacebookIcon
