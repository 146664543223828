import React from "react";
import styles from "./footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.child}>
        <div className={styles.content_1}>
          <div className={styles.content_1_1}>
            <p className={styles.started}>Get Started</p>
            <div className={styles.started_1}>
              Stay updated with your subscriptions
            </div>
          </div>
          <div className={styles.content_1_2}>
            <button className={styles.Start_trial}>Start Trial</button>
          </div>
        </div>

        <div className={styles.content_2}>
          <div className={styles.content_2_1}>
            <div className={styles.slide}>
              <h2>Quick Links</h2>
              <ul>
                <Link to="/">
                  <li>Home</li>
                </Link>
                <Link to="/about-us">
                  <li>About us</li>
                </Link>
                <Link to={"/features"}>
                  <li>Features</li>
                </Link>
                <Link to={"/contact-us"}>
                  <li>Contact us</li>
                </Link>
              </ul>
            </div>

            <div className={styles.slide}>
              <h2>Features</h2>
              <ul>
                <li>Apps Managements</li>
                <li>Timely reminders</li>
                <li>Optimized Calenders</li>
                <li>Dashboards</li>
                <li>Customer Support</li>
              </ul>
            </div>

            <div className={styles.slide} id={styles.social_media}>
              <div className={styles.socialcontainer}>
                <h2>Social Media</h2>
                <ul>
                  <li>
                    Instagram{" "}
                    <img
                      src="/assets/instagram.png"
                      alt="insta"
                      // width={20}
                      // height={20}
                      style={{ height: "20px", width: "20px" }}
                    />
                  </li>
                  <li>
                    LinkedIn{" "}
                    <img
                      src="/assets/linkedin 1.png"
                      alt="linkedin"
                      width={20}
                      height={20}
                    />
                  </li>
                  <li>
                    Facebook{" "}
                    <img
                      src="/assets/facebook.png"
                      alt="facebook"
                      width={20}
                      height={20}
                    />
                  </li>
                  <li>
                    twitter{" "}
                    <img
                      src="/assets/twitter.png"
                      alt="twitter"
                      width={20}
                      height={20}
                    />
                  </li>
                  <li>
                    You tube{" "}
                    <img
                      src="/assets/youtube.png"
                      alt="youtube"
                      width={20}
                      height={20}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.content_2_2}>
            <p className={styles.address}>
              C-210, Random Street, Address is for sample only
            </p>
            <p className={styles.random}>
              Any random text like a informative note can be come here
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
