import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getCalenderData } from "../../../../redux/features/subscriptionSlice";
import { Tooltip } from "react-tooltip";
import { Spin, Avatar, List } from "antd";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerDemo.scss";
import moment from "moment";
import FullPageLoader from "../../loaders/FullPageLoader";

const subscriptions = [
  {
    sub_id: 9,
    sub_name: "Masterclasses",
    sub_logo: "https://alladdinx.com/assets/mastercalss.png",
    cost: "1",
    end_date: "2024-09-12",
  },
  {
    sub_id: 6,
    sub_name: "HBO Max",
    sub_logo: "https://alladdinx.com/assets/hbomax.png",
    cost: "20",
    end_date: "2024-09-20",
  },
  {
    sub_id: 6,
    sub_name: "Masterclasses",
    sub_logo: "https://alladdinx.com/assets/hbomax.png",
    cost: "20",
    end_date: "2024-09-20",
  },
  {
    sub_id: 6,
    sub_name: "Disney+",
    sub_logo: "https://alladdinx.com/assets/hbomax.png",
    cost: "20",
    end_date: "2024-09-20",
  },
  {
    sub_id: 6,
    sub_name: "YouTube",
    sub_logo: "https://alladdinx.com/assets/hbomax.png",
    cost: "20",
    end_date: "2024-09-20",
  },
  {
    sub_id: 1,
    sub_name: "Netflix",
    sub_logo: "https://alladdinx.com/assets/netflix.png",
    cost: "1212",
    end_date: "2024-09-26",
  },
];

function CalenderViewDemo({ fullSizeCalendar, inlineCalendar }) {
  const dispatch = useDispatch();
  const { calenderData, calenderDataLoader } = useSelector((state) => ({
    ...state.subscription,
  }));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [subscriptionColors, setSubscriptionColors] = useState({});

  const [selectedDates, setSelectedDates] = useState([new Date("2023-10-10"), new Date("2023-10-15"), new Date("2023-11-05")]);
  const [displayedMonth, setDisplayedMonth] = useState(new Date());
  const [highlightDates, setHighlightDates] = useState([]);

  const isDateHighlighted = (date) => selectedDates.some((selectedDate) => selectedDate.getDate() === date.getDate() && selectedDate.getMonth() === date.getMonth() && selectedDate.getFullYear() === date.getFullYear());

  const getSubscriptionsByDate = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return calenderData.filter((sub) => moment(sub.end_date).format("YYYY-MM-DD") === formattedDate);
  };

  const handleChangeMonth = (date) => {
    // setDisplayedMonth(date);
    let data = {
      calender_month: new Date(date).getMonth() + 1,
      calender_year: new Date(date).getFullYear(),
    };
    dispatch(getCalenderData(data)).then((res) => {
      let dates = [];
      if (Array.isArray(res?.payload)) {
        res?.payload?.map((item) => {
          dates.push(new Date(item.end_date));
        });
        setSelectedDates(dates);
          const highlightData = (res?.payload ?? [])?.map((sub) => ({
            date: moment(sub.end_date),
            sub_name: sub.sub_name,
            cost: sub.cost,
          }));
          setHighlightDates(highlightData);
      }
    });
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    let data = {
      calender_month: new Date().getMonth() + 1,
      calender_year: new Date().getFullYear(),
    };
    dispatch(getCalenderData(data)).then((res) => {
      // console.log(res, "res");
      let dates = [];
      if (Array.isArray(res?.payload)) {
        res?.payload?.map((item) => {
          dates.push(new Date(item.end_date));
        });
        setSelectedDates(dates);
      }
      const highlightData = res?.payload?.map((sub) => ({
        date: moment(sub.end_date),
        sub_name: sub.sub_name,
        cost: sub.cost,
      }));
      setHighlightDates(highlightData);
    });
  }, []);

  useEffect(() => {
    const colors = {};
    calenderData.forEach((sub) => {
      if (!colors[sub.sub_name]) {
        colors[sub.sub_name] = getRandomColor();
      }
    });
    setSubscriptionColors(colors);
  }, []);

  const renderDayContents = (day, date) => {
    const subsForDay = getSubscriptionsByDate(date);

    return (
      <div className="calendar-day">
        <span className="day-number">{day}</span>
        <div className="subscriptions-container">
          {subsForDay.length > 0 &&
            subsForDay.map((sub, idx) => (
              <div key={idx} className="subscription-badge" style={{ backgroundColor: subscriptionColors[sub.sub_name] || getRandomColor() }}>
                {sub.sub_name} - ${sub.cost}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <>
    {fullSizeCalendar ? (
      <div className="calendar-container">
      <DatePicker inline minDate={new Date()} onMonthChange={(date) => handleChangeMonth(date)} selected={selectedDate} onChange={(date) => setSelectedDate(date)} renderDayContents={renderDayContents} />
      </div>
    ) : (
      <DatePicker
      highlightDates={selectedDates}
      calendarClassName={`${fullSizeCalendar} ${inlineCalendar}`}
      inline
      isClearable
      minDate={new Date()}
      onMonthChange={(date) => handleChangeMonth(date)}
      dayClassName={(date) => (isDateHighlighted(date) ? "highlighted-date" : undefined)}
      disabledKeyboardNavigation
      shouldCloseOnSelect={false}
      />
    )}
    {calenderDataLoader && <FullPageLoader />}
    </>
  );
}

export default CalenderViewDemo;

{
  /* {selectedDates.map((date, index) => (
        <a
          key={index}
          data-tooltip-id={`tooltip-${index}`}
          data-tooltip-content={events[date.toISOString().split("T")[0]]}
          data-tooltip-place="top"
          className="selected-date"
        >
          {date.getDate()}
        </a>
      ))}
      {selectedDates.map((date, index) => (
        <Tooltip
          key={index}
          id={`tooltip-${index}`}
          effect="solid"
          place="top"
        />
      ))} */
}
