"use client";

import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Grid } from "@mui/material";
import styles from "./sidebar.module.css";
import SubscriprionCards from "../common/commonComponents/SubscriptionCards";
import RecommendedApp from "../HomePage/RecommendedApp";
import { useAuth } from "../../context/authContext";
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useLocation } from "react-router-dom";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const { windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { logout, userData, isUserSubscribed } = useAuth();
  const handleLogout = async () => {
    await logout();
    window.location.reload();
    localStorage.removeItem("authToken")
    navigate("/")
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  let menuItems = [
    {
      name: "Dashboard",
      icon: "/assets/dashboard_icon.svg",
      link: "/dashboard",
    },
    {
      name: "Profile",
      icon: "/assets/profileicon.svg",
      link: "/dashboard/profile",
    },
    {
      name: "Add New",
      icon: "/assets/add_new_icon.svg",
      link: "/dashboard/add-new",
    },
    {
      name: "Recurring",
      icon: "/assets/recurring_icon.svg",
      link: "/dashboard/recurring",
    },
    {
      name: "Subscription List",
      icon: "/assets/sub_icon.svg",
      link: "/dashboard/subscription-list",
    },
    {
      name: "Transactions",
      icon: "/assets/trans_icon.svg",
      link: "/dashboard/transactions",
    },
    {
      name: "Notifications",
      icon: "/assets/notification_icon.svg",
      link: "/dashboard/notifications",
    },
  ];

  const drawer = (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
      className="drawer-class"
    >
      <Box>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #C8CBD9",
          }}
        >
          <Box
            sx={{
              position: "relative",
              padding: "16px 0px 16px 20px",
              width: "100%",
            }}
          >
            <Link to="/dashboard">
              <img src={"/assets/alladdin_logo.svg"} alt="alladdin_logo" />
            </Link>
          </Box>
        </Toolbar>
        <Grid
          container
          direction="row"
          display={"flex"}
          justifyContent={"space-between"}
          my={2}
          sx={{ px: 2.5, pt: 1 }}
        >
          <div>
            <p className={styles.hi_user}>{`Hi, ${userData?.name}`}</p>
            {!isUserSubscribed && (
              <Link to={"/"} className={styles.go_premium}>
                {`Go Premium >`}{" "}
              </Link>
            )}
          </div>
          <div className={styles.settingIconContainer}>
            <img
              src={"/assets/setting_icon.svg"}
              alt="setting-icon"
              width={20}
              height={20}
            />
          </div>
        </Grid>
        <List>
          {menuItems?.map((text, index) => (
            <Link to={text?.link} key={index.toString()}>
              <ListItem
                key={text.name}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: mobileOpen ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: mobileOpen ? 3 : "auto",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <img
                      src={text.icon || "/assets/dashboard_iocn.svg"}
                      width={20}
                      height={20}
                      alt="down icon"
                      style={{
                        // maxWidth: "100%",
                        height: "auto",
                      }}
                    /> */}
                    <ReactSVG
                      src={text.icon}
                      width={20}
                      height={20}
                      className={`
          ${
            location.pathname
              .replace(/[.,\s]/g, "")
              .toLowerCase()
              .includes(text.name.replace(/[.,\s]/g, "").toLowerCase())
              ? styles.ImgActive
              : styles.imgNotActive
          } `}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={text.name}
                    sx={{
                      ml: "30px",
                      "& span": {
                        fontSize: "18px",
                        fontWeight: "500",
                        fontFamily: "Roboto  !important",
                        color: "#000000",
                      },
                    }}
                   
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Box>
      <Box>
        <List>
          <Link to="/dashboard/terms-of-service">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemText
                primary={"Terms of Service"}
                sx={{
                  ml: "30px",
                  "& span": {
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Roboto  !important",
                    color: "#000000",
                    textDecoration: "underline",
                  },
                }}
              />
            </ListItem>
          </Link>
          <Link to="/dashboard/privacy-policy">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemText
                primary={"Privacy policy"}
                sx={{
                  ml: "30px",
                  "& span": {
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Roboto  !important",
                    color: "#000000",
                    textDecoration: "underline",
                  },
                }}
              />
            </ListItem>
          </Link>
        </List>
        <List sx={{ borderTop: "1px solid #C8CBD9" }}>
          <Link to="/dashboard/help">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: mobileOpen ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: mobileOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={"/assets/help_icon.svg"}
                    width={20}
                    height={20}
                    alt="down icon"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Help"}
                  sx={{
                    ml: "30px",
                    "& span": {
                      fontSize: "18px",
                      fontWeight: "500",
                      fontFamily: "Roboto  !important",
                      color: "#000000",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <ListItem
            key={"logout"}
            disablePadding
            sx={{ display: "block" }}
            onClick={handleLogout}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: mobileOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: mobileOpen ? 3 : "auto",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/assets/signouticon.svg"}
                  width={20}
                  height={20}
                  alt="down icon"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={"logout"}
                sx={{
                  ml: "30px",
                  "& span": {
                    fontSize: "18px",
                    fontWeight: "500",
                    fontFamily: "Roboto  !important",
                    color: "#000000",
                  },
                }}
                // sx={{ opacity: mobileOpen  ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  const container =
    windows !== undefined ? () => windows().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "#F2F2F2",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="#F2F2F2"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              position: "relative",
              padding: "16px 0px 16px 0px",
              width: "150px",
              display: { md: "none", sm: "none", xs: "block" },
            }}
          >
            <img src={"/assets/alladdin_logo.svg"} alt="alladdin_logo" />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open={mobileOpen}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows: PropTypes.func,
};

export default ResponsiveDrawer;
