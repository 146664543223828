import InstagramIcon from "../../svg/InstagramIcon";
import LinkedinIcon from "../../svg/LinkedinIcon";
import FacebookIcon from "../../svg/FacebookIcon";
import TwitterIcon from "../../svg/TwitterIcon";
import YoutubeIcon from "../../svg/YoutubeIcon";
import { NavLink } from "react-router-dom";

const FooterSection = () => {
  return (
    <div className="py-60 bg-primary tab:py-60">
      <div className="max-w-1340 mx-auto px-5 mobile:px-30">
        <div className="flex justify-between tab:grid tab:grid-cols-1 tab:gap-60 ">
        <div>
            <h2 className="text-[40px] mobile:text-[26px]  mobile:leading-normal text-black300 font-semibold leading-[48px] mb-4 mobile:mb-0">Get Started</h2>
            <p className="text-xl mobile:text-base mobile:leading-8 text-gray650 font-normal">stay updated with your subscriptions</p>
          </div>
          <div className="">
            <div>
              <p className="text-xl font-bold text-black400 mb-[13px]">Quick Links</p>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">
                <NavLink to={"/"}>Home</NavLink>
              </a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">
                <NavLink to={"/about-us"}>About Us</NavLink>
              </a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">
                <NavLink to={"/features"}>Features</NavLink>
              </a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">
                <NavLink to={"/contact-us"}>Contact Us</NavLink>
              </a>
            </div>
          </div>
          <div>
              <p className="text-xl font-bold text-black400 mb-[13px]">Features</p>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">Apps Management</a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">Timely reminders</a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">Optimized Calender</a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">Dashboard</a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">Customer support</a>
            </div>
          <div>
            <p className="text-[22px] text-black400 font-normal leading-normal max-w-[310px] mb-[9px]">325 Hillside Ave, Camden, New Jersey(NJ), 08105</p>
            <a
            href="mailto:contact@alladdinx.com"
            className="text-base text-black400 block leading-normal max-w-[310px]">contact@alladdinx.com </a>
            <p className="text-base text-black400 leading-normal max-w-[310px]">(856) 635-1130 </p>
            <div className="flex items-center gap-5 pt-8">
              <a>
              <InstagramIcon />
              </a>
              <a>
              <LinkedinIcon />
              </a>
              <a>
              <FacebookIcon />
              </a>
              <a>
              <TwitterIcon />
              </a>
              <a>
              <YoutubeIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
