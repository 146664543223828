import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoutes, LoginRoutes } from "../context/authContext";
// import Dashboard from "../components/dashboard/Dashboard";
import Layout from "../components/Layouts/layout.js";
import DashBoardLayout from "../components/Layouts/DashBoardLayout.js";

//=========================================Authentication module=========================================//
import Login from "../components/login";
import Signup from "../components/signup";
import Explore from "../components/explore";
import Features from "../components/features";
import Contact from "../components/contactus";
import About from "../components/aboutus";

import DashboardComponent from "../components/PostLoginPages/Dashboard";
import AddNew from "../components/addnew";
import Recurring from "../components/PostLoginPages/Recurring";
import Transactions from "../components/PostLoginPages/Transactions";
import SubscriptionList from "../components/PostLoginPages/SubscriptionList";
import Subscription from "../components/PostLoginPages/subscription/index";
import Notifications from "../components/PostLoginPages/Notifications";
import Profile from "../components/PostLoginPages/Profile";
import TermsOfService from "../components/PostLoginPages/termsofservice";
import PrivacyPolicy from "../components/PostLoginPages/privacypolicy";
import SuccessPage from "../components/SuccessPage";
import FailedPage from "../components/CancelPage";
import ManageSubscription from "../components/PostLoginPages/managesubscription/index.js";
import HelpComponent from "../components/PostLoginPages/help/index.js";
import Wrapper from "../components/wrapper/index.js";
import FeaturesPage from "../modules/features/index.js";
import AboutusPage from "../modules/aboutus/index.js";
import Contactus from "../modules/contactus/index.js";
import AdminDashboard from "../modules/adminDashboard/index.js";
import MySubscription from "../modules/mySubscription/index.js";
import ForgotPassword from "../components/forgotPassword/index.js";
import Settings from "../modules/setting/index.js";

function MainRoutes() {
  return (
    <Routes>
      {/* Authenticate Routes */}
      <Route element={<LoginRoutes />}>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>
      <Route path="/" element={ <Wrapper> <Explore /> </Wrapper>} />
      <Route path="/features" element={ <Wrapper> <FeaturesPage /> </Wrapper>} />
      <Route path="/features-page" element={<Wrapper><Features /></Wrapper>} />
      <Route path="/about-us" element={<Wrapper><AboutusPage /></Wrapper>} />
        <Route path="/contact-us" element={<Wrapper><Contactus /></Wrapper>} />
        <Route path="/contact-us-page" element={<Wrapper><Contact /></Wrapper>} />
        <Route path="/about-us-page" element={<Wrapper><About /> </Wrapper>} />
      <Route
        element={
          <Layout>
            <LoginRoutes />
          </Layout>
        }
      >
       
        {/* <Route path="/features" element={<Features />} /> */}
        {/* <Route path="/features" element={<Wrapper><Features /></Wrapper>} />
        <Route path="/contact-us" element={<Wrapper><Contact /></Wrapper>} />
        <Route path="/about-us" element={<Wrapper><About /> </Wrapper>} /> */}
      </Route>

      {/* Protected Routes */}
      <Route
        element={
          <DashBoardLayout>
            <PrivateRoutes />
          </DashBoardLayout>
        }
      >
        <Route path="/dashboard" element={<AdminDashboard />} />
        <Route path="/dashboard/add-new" element={<AddNew />} />
        <Route path="/dashboard/recurring" element={<Recurring />} />
        <Route path="/dashboard/transactions" element={<Transactions />} />
        <Route path="/dashboard/my-subscription" element={<MySubscription />} />
        <Route path="/dashboard/setting" element={<Settings />} />
        <Route
          path="/dashboard/subscription-list"
          element={<SubscriptionList />}
        />
        {/* <Route path={`/dashboard/profile`} element={<Profile />} /> */}
        <Route
          path={`/dashboard/manage-subscription`}
          element={<ManageSubscription />}
        />
        <Route
          path={`/dashboard/details/:sub_name/:sub_id`}
          element={<Subscription />}
        />
        <Route path="/dashboard/notifications" element={<Notifications />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route
          path="/dashboard/terms-of-service"
          element={<TermsOfService />}
        />
        <Route path="/dashboard/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/dashboard/help" element={<HelpComponent />} />
        {/* Wildcard Route */}
        <Route path="*" element={<h4> Error No page Found</h4>} />
      </Route>
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/cancel" element={<FailedPage />} />
      {/* Unauthenticated Route */}

      {/* <Route path='/login' element={<LoginRoutes><Login /></LoginRoutes>} /> */}
    </Routes>
  );
}

export default MainRoutes;
