import React, { useState } from 'react'

export default function Settings() {
    const [dropdown, setDropdown] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <div>
            <div className=' pb-5 border-b border-solid border-borderColor1'>
                <h2 className='font-medium text-xl text-black300'>
                    Settings
                </h2>
            </div>
            <div className='bg-white p-5 rounded-xl mt-5'>
                <table className='w-full border-collapse'>
                    <thead>
                        <tr className='text-base mobile:text-xs text-gray600 font-medium text-left border-b border-solid border-borderColor1'>
                            <th className='p-3 mobile:p-2'>Notification Type</th>
                            <th className='p-3 mobile:p-2 w-32 mobile:w-16'>Email </th>
                            <th className='p-3 mobile:p-2 w-32 mobile:w-16'>Push</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='text-base mobile:text-xs text-black300 font-medium text-left border-b border-solid border-borderColor1'>
                            <td className='p-3 mobile:p-2'>
                                Renewal Updates
                            </td>
                            <td className='p-3 mobile:p-2'>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                </label>
                            </td>
                            <td className='p-3 mobile:p-2'>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                </label>
                            </td>
                        </tr>
                        <tr className='text-base mobile:text-xs text-black300 font-medium text-left '>
                            <td className='p-3 mobile:p-2'>
                                Renewal Updates
                            </td>
                            <td className='p-3 mobile:p-2'>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                </label>
                            </td>
                            <td className='p-3 mobile:p-2 '>
                                <label class="switch">
                                    <input type="checkbox" />
                                    <span class="slider"></span>
                                </label>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <h2 className='font-medium text-xl text-black300 mt-5'>
                Currency
            </h2>
            <div className='bg-white p-5 rounded-xl mt-5'>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center gap-4'>
                        <p className='text-base mobile:text-xs font-medium text-black300'>
                            System Currency
                        </p>
                        <i class="fa-solid fa-pen-to-square cursor-pointer mobile:text-xs" onClick={() => setModalOpen(!modalOpen)}></i>
                    </div>
                    <p className='text-base mobile:text-xs font-medium text-black300'>
                        ₹-Ruppes
                    </p>
                </div>
            </div>
            {
                modalOpen && (
                    <div className='fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] z-10 flex items-center justify-center'>
                        <div className='bg-white rounded-xl mobile:rounded-md w-[580px] mobile:w-[calc(100%-20px)]'>
                            <div className='border-solid border-b border-borderColor1 p-4 flex items-center justify-between'>
                                <p className='text-xl mobile:text-base font-medium text-black300'>
                                    Currency
                                </p>
                                <i class="fa-solid fa-xmark cursor-pointer text-xl" onClick={()=> setModalOpen(false)}></i>
                            </div>
                            <div className='p-4'>
                                <label className='text-sm text-gray700 font-medium block pb-2'>System currency</label>
                                <div className="relative">
                                    <div onClick={() => setDropdown(!dropdown)} className="w-full cursor-pointer bg-[#f5f5f5] p-3 text-sm flex items-center justify-between font-medium text-black300 rounded">
                                        <span className='text-sm'>
                                            System currency
                                        </span>
                                        <i class="fa-solid fa-chevron-down text-black300 cursor-pointer"></i>
                                    </div>
                                    <div className={dropdown ? "cus-dropdown-design show" : "cus-dropdown-design hide"}>
                                        <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" >
                                            7 Days
                                        </span>
                                        <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" >
                                            30 Days
                                        </span>
                                        <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" >
                                            2 Months
                                        </span>
                                        <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" >
                                            Custom Date
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    )
}
