import React from 'react'

const LinkedinIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <g clip-path="url(#clip0_1385_1334)">
                <path d="M16.6283 16.5698H13.8946V12.2889C13.8946 11.268 13.8765 9.95435 12.4728 9.95435C11.0492 9.95435 10.831 11.0662 10.831 12.2153V16.5698H8.09827V7.76616H10.7228V8.96889H10.7592C11.2946 8.05435 12.2892 7.50798 13.3483 7.54707C16.1192 7.54707 16.6292 9.3698 16.6292 11.7398L16.6283 16.5698ZM5.01373 6.56253C4.13736 6.56253 3.42736 5.85253 3.42736 4.97616C3.42736 4.0998 4.13736 3.3898 5.01373 3.3898C5.89009 3.3898 6.60009 4.0998 6.60009 4.97616C6.60009 5.85253 5.89009 6.56253 5.01373 6.56253ZM6.38009 16.5698H3.64373V7.76616H6.38009V16.5698ZM17.991 0.851619H2.27009C1.52736 0.843437 0.918271 1.43889 0.90918 2.18162V17.9671C0.918271 18.7107 1.52736 19.3062 2.27009 19.298H17.991C18.7355 19.3071 19.3474 18.7116 19.3574 17.9671V2.18071C19.3465 1.43616 18.7346 0.84071 17.991 0.85071" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1385_1334">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.139648)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LinkedinIcon
