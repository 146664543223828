import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import * as subsApi from "../api/subs";

const initialState = {
  loading: false,
  updateSubscLoader: false,
  notifications: [],
  recomendedsubscription: [],
  allSubscriptions: [],
  subscriptionSummary: [],
  usersCurrentSub: [],
  usersCurrentSubLoader: false,
  usersCurrentCategory: [],
  currentAllSubs:[],
  currentAllSubsLoader:false,
  currentSubsList: [],
  streamPlayGraph:[],
  upcomingSubsData:[],
  upcomingSubsLoader:false,
  averageCoastsData:[],
  averageCoastsLoader:false,
  userSpendData: [],
  borwseAlladdinData: [],
  borwseAlladdinLoader: false,
  deleteSubscriptionLoader: false,
  deleteTransactionLoader: false,
  subscriptionStatusLoader: false,
  alladdinCategory: [],
  recurringDataUpcoming: [],
  recurringDataAll: [],
  subscriptionAmountGraphData: [],
  calenderData: [],
  calenderDataLoader: false,
  allUserTransactionsData: [],
  allUserTransactionsLoader: false,
  perticularSubsData: "",
  perticularGraphData: [],
  successfullmessage: "",
  error: "",
};

export const GetUserSubscriptions = createAsyncThunk(
  "subscription/usersubsriptions",
  async ({ data }, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getUserSubscriptions(data);
      if (resp.data.code == 200) {
        return resp.data;
      } else if (resp.data.code == 402) {
        console.log(resp.data.message);
        return resp.data.attempts;
      } else {
        console.log(resp.data.message);
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);
// export const showNotifications = createAsyncThunk(
//   "subscription/shownotification",
//   async (data, { rejectWithValue }) => {
//     try {
//       const resp = await subsApi.getNotifications(data);
//       if (resp.data.code == 200) {
//         return resp.data.data;
//       } else {
//         console.log(resp.data.message, "message");
//         return rejectWithValue(resp.data.message);
//       }
//     } catch (error) {
//       console.log(error, "error shownotification");
//       rejectWithValue(error.resp.data);
//     }
//   }
// );
export const showNotifications = createAsyncThunk(
  "subscription/shownotification",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getNotifications(data);
      if (resp.data.code == 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const userSpendingData = createAsyncThunk(
  "subscription/userspending",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.userSpendingDataApi(formData);
      if (resp.data.code == 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const recurringSubscriptionsData = createAsyncThunk(
  "subscription/recurringsubscriptions",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.recurringSubscriptionsApi(formData);
      if (resp.data.code == 200) {
        return resp.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const getCalenderData = createAsyncThunk(
  "subscription/calendersubscription",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getCalenderDataApi(formData);
      if (resp.data.code == 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetALLSubscriptions = createAsyncThunk(
  "subscription/getallsubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getAllSubscriptions();
      if (resp.data.code == 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetUserCurrentSubscriptions = createAsyncThunk(
  "subscription/getCurrentUsersSubscriptions",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getCurrentUsersSubscriptions(data);
      if (resp.data.success) {
        return resp.data;
      } else {
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetUserCurrentCategory = createAsyncThunk(
  "subscription/GetUserCurrentCategory",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getUserCurrentCategoryAPI(data);
      if (resp.data.success) {
        return resp.data;
      } else {
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const getCurrentAllSubscriptions = createAsyncThunk(
  "subscription/getAllCurrentSubscriptions",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getAllCurrentSubscriptions(data);
      if (resp.data.code == 200) {
        return resp.data;
      } else {
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetRecommendedSubscriptions = createAsyncThunk(
  "subscription/getrecommendedsubscriptions",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getRecommendedSubscriptions();
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const currentSubscriptionList = createAsyncThunk(
  "subscription/currentsubscription",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await subsApi.addCurrentSubscriptionApi();
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const activityStreamGraph = createAsyncThunk(
  "subscription/subscriptiongraphNewAPI",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.activityStreamGraphAPI(formData);
      console.log( "resppppppppppp", resp);
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const upcomingSubscription = createAsyncThunk(
  "subscription/upcomingSubscription",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getAllUpcomingSubscription(formData);
      if (resp?.data?.success) {
        return resp.data.payload;
      } else {
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const averageCoats = createAsyncThunk(
  "subscription/averageCoats",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getAvrageCoasts();
      if (resp?.data?.success) {
        return resp.data.payload;
      } else {
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      rejectWithValue(error.resp.data);
    }
  }
);

export const allTransactionsData = createAsyncThunk(
  "subscription/alltransactions",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.allTransactionsApi(formData);
      // console.log(resp, "resp");
      console.log("resppppppppppppp", resp)
      if (resp.data.success) {
        return resp.data.payload;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);

export const allSubscriptionAmountsGraph = createAsyncThunk(
  "subscription/subscriptiongraph",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.allSubscriptionAmountsGraphApi(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error shownotification");
      rejectWithValue(error.resp.data);
    }
  }
);
export const AddSubscription = createAsyncThunk(
  "subscription/addsubscription",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.addSubscription(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.message;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);

export const UpdateSubscription = createAsyncThunk(
  "subscription/updatesubscription",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.updateSubscriptionApi(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.message;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "subscription/deleteSubscription",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.deleteSubscriptionApi(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.message;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);

export const deleteTransaction = createAsyncThunk(
  "subscription/deleteTransaction",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.deleteTransactionApi(formData);
      // console.log(resp, "resp");
      if (resp.data.success) {
        return resp.data.message;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);

export const handleSubscriptionStatus = createAsyncThunk(
  "subscription/handleSubscriptionStatus",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.subscriptionStatusApi(formData);
      console.log(resp, "resp...........");
      if (resp?.data?.success) {
        return resp.data.messages;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetBrowseAllData = createAsyncThunk(
  "subscription/getCatSub",
  async (data, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getBrowseAlladdin(data);
      // console.log(resp, "resp");
      if (resp.data.success) {
        return resp.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetAllCategory = createAsyncThunk(
  "subscription/getAllCategory",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getAllCategoryAll();
      // console.log(resp, "resp");
      if (resp.data.success) {
        return resp.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);

export const GetSubsDetails = createAsyncThunk(
  "subscription/getSubsDetails",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getSubsDetails(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);
export const Getsubsgraphs = createAsyncThunk(
  "subscription/getsubsgraphs",
  async (formData, { rejectWithValue }) => {
    try {
      const resp = await subsApi.getsubsgraphs(formData);
      // console.log(resp, "resp");
      if (resp.data.code === 200) {
        return resp.data.data;
      } else {
        console.log(resp.data.message, "message");
        return rejectWithValue(resp.data.message);
      }
    } catch (error) {
      console.log(error, "error addsubscription");
      rejectWithValue(error.resp.data);
    }
  }
);
export const removeNotification = createAsyncThunk(
  "subscription/removenotification",
  async (formData, { rejectWithValue }) => {
    try {
      let result = await subsApi.removeNotificationApi(formData);

      if (result?.data?.code == 200)
        message.success("Removed notification successfully");
      else message.error(result?.data?.message, "Error removing notification");
      return result?.data;
    } catch (error) {
      // message.error(error, "Error removing notification");
      return rejectWithValue(error);
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(showNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = action.payload;
    });
    builder.addCase(showNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(removeNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeNotification.fulfilled, (state, action) => {
      state.loading = false;
      // state.notifications = action.payload;
    });
    builder.addCase(removeNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(GetRecommendedSubscriptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetRecommendedSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.recomendedsubscription = action.payload;
    });
    builder.addCase(GetRecommendedSubscriptions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(GetALLSubscriptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetALLSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.allSubscriptions = action.payload;
    });
    builder.addCase(GetALLSubscriptions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(GetUserCurrentSubscriptions.pending, (state) => {
      state.loading = true;
      state.usersCurrentSubLoader = true;
    });
    builder.addCase(GetUserCurrentSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.usersCurrentSub = action?.payload?.payload;
      state.subscriptionSummary = action?.payload?.payload;
      state.usersCurrentSubLoader = false;
    });
    builder.addCase(GetUserCurrentSubscriptions.rejected, (state, action) => {
      state.loading = false;
      state.usersCurrentSubLoader = false;
      state.error = action.payload;
    });

    builder.addCase(GetUserCurrentCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetUserCurrentCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.usersCurrentCategory = action?.payload?.payload;
    });
    builder.addCase(GetUserCurrentCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getCurrentAllSubscriptions.pending, (state) => {
      state.loading = true;
      state.currentAllSubsLoader = true;
    });
    builder.addCase(getCurrentAllSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.currentAllSubsLoader = false;
      state.currentAllSubs = action?.payload?.data;
    });
    builder.addCase(getCurrentAllSubscriptions.rejected, (state, action) => {
      state.loading = false;
      state.currentAllSubsLoader = false;
      state.error = action.payload;
    });

    builder.addCase(currentSubscriptionList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(currentSubscriptionList.fulfilled, (state, action) => {
      state.loading = false;
      state.currentSubsList = action.payload;
    });
    builder.addCase(currentSubscriptionList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(activityStreamGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(activityStreamGraph.fulfilled, (state, action) => {
      console.log("actionnnnnnnnnnnnn", action)
      state.loading = false;
      state.streamPlayGraph = action.payload;
    });
    builder.addCase(activityStreamGraph.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(upcomingSubscription.pending, (state) => {
      state.upcomingSubsLoader = true;
    });
    builder.addCase(upcomingSubscription.fulfilled, (state, action) => {
      state.upcomingSubsLoader = false;
      state.upcomingSubsData = action.payload;
    });
    builder.addCase(upcomingSubscription.rejected, (state, action) => {
      state.upcomingSubsLoader = false;
      state.error = action.payload;
    });

    builder.addCase(averageCoats.pending, (state) => {
      state.averageCoastsLoader = true;
    });
    builder.addCase(averageCoats.fulfilled, (state, action) => {
      state.averageCoastsLoader = false;
      state.averageCoastsData = action.payload;
    });
    builder.addCase(averageCoats.rejected, (state, action) => {
      state.averageCoastsLoader = false;
      state.error = action.payload;
    });

    builder.addCase(userSpendingData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userSpendingData.fulfilled, (state, action) => {
      state.loading = false;
      state.userSpendData = action.payload;
    });
    builder.addCase(userSpendingData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(recurringSubscriptionsData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(recurringSubscriptionsData.fulfilled, (state, action) => {
      state.loading = false;
      state.recurringDataUpcoming = action.payload.data;
      state.recurringDataAll = action.payload.data1;
    });
    builder.addCase(recurringSubscriptionsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getCalenderData.pending, (state) => {
      state.loading = true;
      state.calenderDataLoader = true;
    });
    builder.addCase(getCalenderData.fulfilled, (state, action) => {
      state.loading = false;
      state.calenderDataLoader = false;
      state.calenderData = action.payload;
    });
    builder.addCase(getCalenderData.rejected, (state, action) => {
      state.loading = false;
      state.calenderDataLoader = false;
      state.calenderData = [];
      state.error = action.payload;
    });

    builder.addCase(allTransactionsData.pending, (state) => {
      state.allUserTransactionsLoader = true;
    });
    builder.addCase(allTransactionsData.fulfilled, (state, action) => {
      console.log("actionnnnnnnnnnnnnn", action)
      state.allUserTransactionsLoader = false;
      state.allUserTransactionsData = action.payload;
    });
    builder.addCase(allTransactionsData.rejected, (state, action) => {
      state.allUserTransactionsLoader = false;
      state.error = action.payload;
    });

    builder.addCase(allSubscriptionAmountsGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(allSubscriptionAmountsGraph.fulfilled, (state, action) => {
      state.loading = false;
      state.subscriptionAmountGraphData = action.payload;
    });
    builder.addCase(allSubscriptionAmountsGraph.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(AddSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AddSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.successfullmessage = action.payload;
    });
    builder.addCase(AddSubscription.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.successfullmessage = action.payload;
    });

    builder.addCase(UpdateSubscription.pending, (state) => {
      state.loading = true;
      state.updateSubscLoader = true;
    });
    builder.addCase(UpdateSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.updateSubscLoader = false;
      state.successfullmessage = action.payload;
    });
    builder.addCase(UpdateSubscription.rejected, (state, action) => {
      state.loading = false;
      state.updateSubscLoader = false;
      state.error = action.payload;
      state.successfullmessage = action.payload;
    });

    builder.addCase(deleteSubscription.pending, (state) => {
      state.deleteSubscriptionLoader = true;
    });
    builder.addCase(deleteSubscription.fulfilled, (state, action) => {
      state.deleteSubscriptionLoader = false;
    });
    builder.addCase(deleteSubscription.rejected, (state, action) => {
      state.deleteSubscriptionLoader = false;
      state.error = action.payload;
    });

    builder.addCase(deleteTransaction.pending, (state) => {
      state.deleteTransactionLoader = true;
    });
    builder.addCase(deleteTransaction.fulfilled, (state, action) => {
      state.deleteTransactionLoader = false;
    });
    builder.addCase(deleteTransaction.rejected, (state, action) => {
      state.deleteTransactionLoader = false;
      state.error = action.payload;
    });

    builder.addCase(handleSubscriptionStatus.pending, (state) => {
      state.subscriptionStatusLoader = true;
    });
    builder.addCase(handleSubscriptionStatus.fulfilled, (state, action) => {
      state.subscriptionStatusLoader = false;
    });
    builder.addCase(handleSubscriptionStatus.rejected, (state, action) => {
      state.subscriptionStatusLoader = false;
      state.error = action.payload;
    });

    builder.addCase(GetBrowseAllData.pending, (state) => {
      state.loading = true;
      state.borwseAlladdinLoader = true;
    });
    builder.addCase(GetBrowseAllData.fulfilled, (state, action) => {
      console.log("actionnnnnnnnnnn", action)
      state.loading = false;
      state.borwseAlladdinData = action.payload.payload;
      state.borwseAlladdinLoader = false;
    });
    builder.addCase(GetBrowseAllData.rejected, (state, action) => {
      state.loading = false;
      state.borwseAlladdinLoader = false;
      state.error = action.payload;
      // state.successfullmessage = action.payload;
    });

    builder.addCase(GetAllCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetAllCategory.fulfilled, (state, action) => {
      console.log("actionnnnnnnnnnn", action)
      state.loading = false;
      state.alladdinCategory = action.payload.payload;
    });
    builder.addCase(GetAllCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(GetSubsDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetSubsDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.perticularSubsData = action.payload.data;
    });
    builder.addCase(GetSubsDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // state.successfullmessage = action.payload;
    });

    builder.addCase(Getsubsgraphs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Getsubsgraphs.fulfilled, (state, action) => {
      state.loading = false;
      state.perticularGraphData = action.payload;
    });
    builder.addCase(Getsubsgraphs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // state.successfullmessage = action.payload;
    });
  },
});

export default subscriptionSlice.reducer;
