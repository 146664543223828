import React from 'react'

const YoutubeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M19.5817 5.29473C19.3517 4.42889 18.6742 3.74639 17.8133 3.51473C16.2542 3.09473 10 3.09473 10 3.09473C10 3.09473 3.74583 3.09473 2.18583 3.51473C1.32583 3.74639 0.648333 4.42806 0.418333 5.29473C0 6.86473 0 10.1397 0 10.1397C0 10.1397 0 13.4147 0.418333 14.9847C0.648333 15.8506 1.32583 16.5331 2.18667 16.7647C3.74583 17.1847 10 17.1847 10 17.1847C10 17.1847 16.2542 17.1847 17.8142 16.7647C18.6742 16.5331 19.3517 15.8514 19.5825 14.9847C20 13.4147 20 10.1397 20 10.1397C20 10.1397 20 6.86473 19.5817 5.29473ZM7.955 13.1139V7.16556L13.1817 10.1397L7.955 13.1139Z" fill="black"/>
  </svg>
  )
}

export default YoutubeIcon
