import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { activityStreamGraph } from "../../../redux/features/subscriptionSlice";

const ActivityStream = () => {
  const dispatch = useDispatch();
  const { streamPlayGraph } = useSelector((state) => state.subscription);

  console.log("streamPlayGraphhhhhhhhhhh", streamPlayGraph);

  // useEffect(() => {
  //   dispatch(activityStreamGraph({days: 365}));
  // }, []);

  const seriesData = streamPlayGraph?.map((item) => ({ x: item?.x, y: item?.y }));

  const options = {
    series: streamPlayGraph?.map((item) => ({ x: item?.x, y: item?.y })),
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = streamPlayGraph[dataPointIndex];
        return `
            <div style="padding: 10px; background-color: #333; color: #fff; text-align: center; display: flex; gap: 10px;">
        <div style="justify-content: center; display: flex; flex-direction: column;">
          <img src="${dataPoint.logo}" alt="${dataPoint.x}" style="width: 29px; height: 29px; border: 1px solid #fff; border-radius: 5px;">
        </div>
        <div>
          <div style="font-size: 14px; font-weight: bold;">${dataPoint.x}</div>
          <div>${series[seriesIndex][dataPointIndex]} Hrs played</div>
        </div>
      </div>
          `;
      },
    },
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    colors: ["#26A0FC", "#26E7A6", "#FF6178", "#8B75D7", "#26tbnu", "#46B3A9", "#D830EB"],
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: "#A0A0A0",
          fontSize: "12px",
        },
        formatter: function (value) {
          return `${value} Hr`;
        },
      },
    },
    xaxis: {
      categories: streamPlayGraph?.map((item) => item?.x),
      labels: {
        style: {
          colors: "#A0A0A0",
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: true,
      borderColor: '#e0e0e0',
      strokeDashArray: 6,
    }
  };

  return (
    <div className="py-6">
      <h2 className="text-xl font-medium text-black mb-2">Activity Stream</h2>
      <div className="bg-white h-[250px] rounded-2xl">
        <ReactApexChart options={options} series={[{ data: seriesData }]} type="bar" height={250} />
      </div>
    </div>
  );
};

export default ActivityStream;
