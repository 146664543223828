"use client";
import React, { useState, useEffect } from "react";
// import Link from "next/link";
// import { useRouter } from "next/navigation";
import axios from "axios";
import styles from "./signup.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useAuth } from "../../context/authContext";
import AppConstants from "../../constants/AppConstants";
import Logo from "../../assets/logo/logo.svg";
import GoogleIcon from "@mui/icons-material/Google";
import EyeShow from "../../assets/images/eyeShow.png";
import EyeHidden from "../../assets/images/eyeHidden.png";

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validationMessages, setValidationMessages] = useState({
    email: "",
    password: "",
  });
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState({});
  const navigate = useNavigate();

  const { signupUser } = useAuth();

  // const router = useRouter();
  // const handleclick = async () => {
  //   // console.log("first");

  //   window.open(
  //     `${AppConstants.baseURL}/auth/google`,
  //     "_self"
  //   );
  // };

  const handleclick = async () => {
    // const resp = await axios.post(`${AppConstants.baseURL}/auth/logout`, {
    //   withCredentials: true,
    // });
    window.open(`${AppConstants.baseURL}/auth/google`, "_self");
    // window.open(
    //   "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&redirect_uri=https%3A%2F%2Falladdinx.com%2Fapi%2Fauth%2Fgoogle%2Fcallback&scope=profile%20email&client_id=210434425102-nn9m8fde1roabp80dvl913o4sqv5c9dj.apps.googleusercontent.com&service=lso&o2v=2&flowName=GeneralOAuthFlow",
    //   "_self"
    // );
  };

  const validateEmail = (email) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const validPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return validPasswordRegex.test(password); // Placeholder, replace with actual validation
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      const isValidEmail = validateEmail(value);
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        email: isValidEmail ? "" : "Please enter a valid email address",
      }));
    }

    if (name === "password") {
      const isValidPassword = validatePassword(value);
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        password: isValidPassword ? "" : "Password should contain special characters and alphapets",
      }));
    }
  };

  const handleSignup = async () => {
    if (ValidateData(inputValue)) {
      setIsLoading(true);
      // if (isValidEmail && isValidPassword && !inputValue.name) {
      // if (formData.confirmPassword === formData.password) {
      let datatoSend = {};
      datatoSend.name = inputValue.name;
      datatoSend.email = inputValue.email;
      datatoSend.password = inputValue.password;
      let res = await signupUser(datatoSend);
      if (res) {
        setIsLoading(false);
        setInputValue({});
      } else {
        setIsLoading(false);
      }
      // } else {
      //   message.error("Password and ConfirmPassword do not match");
      // }
      // Form submission logic here

      // let resp = await axios.post(
      //   "https://alladdinx.com/api/auth/signup",
      //   // "http://localhost:5800/api/auth/signup",
      //   formData
      // );

      // if (resp && resp?.data?.code == 200) {
      //   // alert(resp.data.message ? resp.data.message : "user register");
      //   message.success(
      //     resp.data.message ? resp.data.message : "user register"
      //   );
      //   navigate("/dashboard");

      //   // console.log(resp.data.message ? resp.data.message : "user register");
      //   // router.push("/login");
      //   // let getProfile_resp = await axios.get(
      //   //   // "https://alladdinx.com/api/auth/profile",
      //   //   "http://localhost:5800/api/auth/profile"
      //   // );
      //   // console.log(getProfile_resp, "getProfile_resp");
      // } else {
      //   // alert(resp.data.message ? resp.data.message : "something went wrong");
      //   message.error(
      //     resp.data.message ? resp.data.message : "something went wrong"
      //   );

      //   // console.log(
      //   //   resp.data.message ? resp.data.message : "user not register"
      //   // );
      // }
      // } else {
      //   if (!isValidEmail) {
      //     message.error("Please enter a valid email address");
      //   }
      //   if (!isValidPassword) {
      //     message.error("Password should contain special characters and alphapets");
      //   }
      //   if (!inputValue.name) {
      //     message.error("Please enter your full name");
      //   }
      // }
    }
  };

  const ValidateData = (values) => {
    let errors = {};
    let isValid = true;
    const emailreRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values?.name) {
      errors.name = "Full name is required!";
      isValid = false;
    }

    if (!values.email) {
      errors.email = "Email id is required!";
      isValid = false;
    } else if (values.email && !emailreRegex.test(values.email)) {
      errors.email = "Email is in incorrect format";
      isValid = false;
    }
    if (!values.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (values.password && values.password.length <= 5) {
      errors.password = "Password is to short";
      isValid = false;
    }
    setErrMessages(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trimStart();
    if (name === "name") {
      if (value !== trimmedValue) {
        setInputValue({ ...inputValue, [name]: trimmedValue });
      } else {
        setInputValue({ ...inputValue, [name]: value });
        setErrMessages({ ...errMessages, [name]: "" });
      }
    } else {
      if (e.target.value.trim() !== "") {
        setInputValue({ ...inputValue, [e.target.name]: e.target.name === "email" ? e?.target?.value?.trim()?.toLowerCase() : e?.target?.value?.trim() });
        setErrMessages({ ...errMessages, [e.target.name]: "" });
      } else {
        setInputValue({ ...inputValue, [e.target.name]: "" });
      }
    }
  };

  useEffect(() => {
    document.body.style.overflow = "";
  }, []);

  return (
    <>
      {/* <div className={styles.main}>
      <div className={styles.child}>
        <div className={styles.child_1}>
          <div className={styles.child_1_div}>
            <div className={styles.child_1_1}>
              <p>Simplify your</p>
              <p id="subs">Subscriptions,</p>
              <p>Amplify your</p>
              <p>Savings</p>
            </div>
            <div className={styles.child_1_2}>
              <img src="/assets/alladinblacklogo.png" alt="alladin" />
            </div>
          </div>
        </div>
        <div className={styles.child_2}>
          <div className={styles.child_2_div}>
            <div className={styles.child_2_1}>
              <p>Create Account</p>
            </div>

            <div className={styles.child_2_4}>
              <div className={styles.input_field}>
                <input
                  placeholder="Enter Your Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  type="text"
                />
              </div>
              <div className={styles.input_field}>
                <input
                  placeholder="Enter Your Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.input_field}>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className={styles.input_field}>
                <input
                  type="password"
                  placeholder=" Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
              </div>
              <p className={styles.Login_btn}>
                <button type="submit" onClick={handleSignup}>
                  Create Account
                </button>
              </p>
              <p className={styles.Sign_up_here}>
                Already have a account?{" "}
                <span>
                  <Link Link to={"/login"}>
                    Login
                  </Link>
                </span>
              </p>
            </div>
            <div className={styles.child_2_3}>
              <p>- OR -</p>
            </div>
            <div className={styles.child_2_2}>
              <button onClick={handleclick}>Sign up with Google</button>
            </div>
          </div>
        </div>
      </div>
    </div> */}
      <div className="h-screen bg-greenLight tab:h-auto">
        <div className="p-14 h-full mobile:py-0 mobile:px-0 ">
          <div className="max-w-1340 h-full mx-auto px-5 mobile:px-0">
            <div className="bg-primary h-full rounded-[20px] mobile:h-auto mobile:pb-60 mobile:rounded-none">
              <div className="grid h-full grid-cols-2 tab:grid-cols-1 gap-100 mobile:gap-0 mobile:pt-60">
                <div className="h-full p-16 mobile:p-5 flex items-center">
                  <div>
                    <div className="pb-16 mobile:pb-10 mobile:flex mobile:items-center mobile:justify-center">
                      <NavLink to="/">
                        <img className="cursor-pointer w-[320px] mobile:w-auto" src={Logo} alt="Logo" />
                      </NavLink>
                    </div>
                    <h2 className="text-[50px] mobile:text-center mobile:text-[32px] mobile:mb-8 font-normal text-black300 mb-60">
                      Simplify Your <span className="font-extrabold">Subscriptions</span>, Amplify Your Savings
                    </h2>
                  </div>
                </div>
                <div className="h-full bg-white rounded-[20px] flex items-center mobile:p-5 p-10 mobile:m-5 overflow-auto" style={{ scrollbarWidth: "none" }}>
                  <div className="w-full">
                    <h2 className="text-[30px] mb-10 text-black300 font-bold tracking-[1.4px]">Create Account</h2>
                    <div className="pb-5">
                      <label className="block text-base text-gray600 pb-4">
                        Full Name<span className="text-redError">*</span> <span className="text-redError text-sm">{errMessages?.name}</span>
                      </label>
                      <input
                        className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent"
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={inputValue?.name}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="pb-5">
                      <label className="block text-base text-gray600 pb-4">
                        Email<span className="text-redError">*</span> <span className="text-redError text-sm">{errMessages?.email}</span>
                      </label>
                      <input
                        className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent"
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={inputValue?.email}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="pb-5">
                      <label className="block text-base text-gray600 pb-4">
                        Password<span className="text-redError">*</span> <span className="text-redError text-sm">{errMessages?.password}</span>
                      </label>
                      <div className="relative flex items-center">
                      <input
                        className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent"
                        type={passwordShow ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={inputValue?.password}
                        onChange={(e) => handleChange(e)}
                      />
                      {passwordShow ? (
                        <img
                          className="absolute right-[10px] top-1/2 translate-y-1/2 cursor-pointer"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          src={EyeShow}
                          alt="EyeShow"
                          width={20}
                          height={20}
                          onClick={() => setPasswordShow(!passwordShow)}
                        />
                      ) : (
                        <img
                          className="absolute right-[10px] top-1/2 translate-y-1/2 cursor-pointer"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          src={EyeHidden}
                          alt="EyeHidden"
                          width={20}
                          height={20}
                          onClick={() => setPasswordShow(!passwordShow)}
                        />
                      )}
                      </div>
                    </div>
                    <div className="flex items-center justify-center pt-4 pb-3">
                      <button disabled={isLoading} className="flex justify-center gap-2.5 p-3 px-9 rounded-full text-base font-semibold bg-primary w-full" onClick={() => handleSignup()}>
                        Create Account {isLoading && <div className="loader"></div>}
                      </button>
                    </div>
                    <p className="text-base font-medium text-gray600 mb-3 text-center">OR</p>
                    <button className=" p-3 px-9 flex items-center gap-3 justify-center rounded-full text-base font-semibold bg-white border border-solid border-primary w-full" onClick={handleclick}>
                      <GoogleIcon style={{ color: "#d0ee11" }} />
                      Google Login
                    </button>
                    <p className="text-base pt-5 font-medium text-black300 text-center">
                      Already have an account?{" "}
                      <Link to="/login">
                        <a className="text-link">Login</a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;

// {
//    <div className={styles.Singup_main_container}>
//       <div className={styles.main_singup_container}>
//         <div className={styles.mainContainer}>
//           <div className={styles.singup_heading_div}>
//             <h1 className={styles.singup_heading}>Create Account</h1>
//           </div>
//           <form onSubmit={handleSignup}>
//             <div className={styles.Singup_Name}>
//               <div className={styles.Name}>Name</div>
//               <div className={styles.Singup_Name_input}>
//                 <input
//                   placeholder="Enter Your Full Name"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleInputChange}
//                   type="text"
//                   className={styles.Singup_Name_inputbox}
//                 />
//               </div>
//             </div>

//             <div className={styles.Singup_Name}>
//               <div className={styles.Name}>Email</div>
//               <div className={styles.Singup_Name_input}>
//                 <input
//                   placeholder="Enter Your Email"
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleInputChange}
//                   className={styles.Singup_Name_inputbox}
//                 />
//                 {validationMessages.email && (
//                   <p id="password_11">{validationMessages.email}</p>
//                 )}
//               </div>
//             </div>

//             <div className={styles.Singup_Name}>
//               <div className={styles.Name}>Password</div>
//               <div className={styles.Singup_Name_input}>
//                 <input
//                   placeholder="Enter Your Password"
//                   type="password"
//                   name="password"
//                   value={formData.password}
//                   onChange={handleInputChange}
//                   className={styles.Singup_Name_inputbox}
//                 />
//                 {validationMessages.password && (
//                   <p id="password_11">{validationMessages.password}</p>
//                 )}
//               </div>
//             </div>

//             <div className={styles.singup_btn_class}>
//               <button className={styles.login_btn}>Signup</button>
//             </div>
//           </form>

//           <div className={styles.googlesignup} onClick={handleclick}>
//             <img
//               src={"/assets/googleimg.png"}
//               className={styles.google_login_img}
//             />
//             <span className={styles.with_google}>Continue with Google</span>
//           </div>

//           <div className={styles.singup_create_acc}>
//             <div className={styles.singup_create_}>
//               Already have an account?
//               <span>
//                 {/* <Link href="/login">Login</Link> */}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
// }
