import React from "react";
import styles from "./sub.module.css";

const DiscriptionSec = ({ sub_description }) => {
  return (
    <div className="pt-5 pb-6">
       <p className="text-[22px] leading-normal text-black300 font-medium mb-2">Description</p>
      <p className="text-base text-black font-normal">
        {sub_description}
      </p>
    </div>
  );
};

export default DiscriptionSec;
