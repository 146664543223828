import React, { useEffect, useState } from "react";
import styles from "./notification.module.css";
import trstyles from "../Transactions/transaction.module.css";
import { Select } from "antd";
import NetflixIcon from "../../../assets/icons/netflix-xs.svg";
import NotificationIcon from "../../../assets/images/NotificationIcon.webp";
import DotIcon from "../../../assets/icons/three-dot.svg";
import NotificationBody from "./NotificationBody";
import { showNotifications } from "../../../redux/features/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";

function Notifications() {
  const dispatch = useDispatch();
  const [searchedNotifications, setSearchedNotifications] = useState([]);
  const [serchedText, setserchedText] = useState("");
  // const [days, setDays] = useState("");
  const { notifications } = useSelector((state) => ({
    ...state.subscription,
  }));
  const handleChange = (value) => {
    // console.log(value.value);
    let data = {};
    data.days = value.value;
    dispatch(showNotifications(data));
    // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };
  const handleSearch = (e) => {
    setserchedText(e.target.value);
    if (e.target.value !== "") {
      let filteredNotifications = notifications.filter((notification) => notification.title.toLowerCase().includes(e.target.value.toLowerCase()));
      setSearchedNotifications(filteredNotifications);
    } else {
      setSearchedNotifications(notifications);
    }
  };

  useEffect(() => {
    let data = {};
    data.days = 30;
    dispatch(showNotifications(data));
  }, []);
  return (
    <>
    <div className="pb-5 border-b border-solid border-borderColor1">
      <h2 className="text-xl text-black300 mobile:text-sm font-medium ">Notification</h2>
      </div>
      <div className="flex items-center justify-between pb-4 pt-5 mobile:grid mobile:grid-cols-1 mobile:gap-3">
        <div className="flex items-center gap-4 mobile:grid mobile:grid-cols-1">
          <div className="w-[344px] mobile:w-full">
            <input className="w-full border border-solid border-borderColor4 rounded h-9 placeholder:text-sm text-sm text-black300 bg-white" type="text" placeholder="Search" />
          </div>
          <div className="w-[100px] mobile:w-full">
            <select className="w-full border border-solid border-borderColor4 rounded h-9 placeholder:text-sm text-sm text-black300 bg-white">
              <option>2 Days</option>
              <option>3 Days</option>
              <option>4 Days</option>
            </select>
          </div>
        </div>
        <p className="text-base font-medium text-gray700 cursor-pointer underline">Manage</p>
      </div>

      {/* <div className={styles.header}>
        <div className={trstyles.searchContainer}>
          <input
            type="text"
            className={trstyles.searchfeild}
            placeholder="search..."
            onChange={handleSearch}
            value={serchedText}
          />
          <img
            src="/assets/searchicon.svg"
            alt="searchicon"
            style={{ width: "16px", height: "16px" }}
          />
        </div>
        <Select
          className="select-class"
          style={{ border: "1px solid rgba(136, 136, 136, 0.4)" }}
          labelInValue
          defaultValue={{
            value: 30,
            label: (
              <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                30 days
              </p>
            ),
          }}
          onChange={handleChange}
          options={[
            {
              value: 60,
              label: (
                <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                  60 days
                </p>
              ),
            },
            {
              value: 30,
              label: (
                <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                  30 days
                </p>
              ),
            },
          ]}
        />
      </div> */}

      <div className="bg-white py-4 pt-0 px-6 rounded-2xl mobile:p-3">
        <p className="text-sm pt-4 font-bold text-black300">Today</p>
        {[...Array(5)].map(() => {
          return (
            <div className="py-4 border-solid border-b border-borderColor5 flex mobile:grid mobile:grid-cols-1 mobile:gap-3 items-center justify-between">
              <div className="flex gap-4">
                <img className="w-12 h-12 min-w-12 block object-cover rounded" src={NetflixIcon} alt="NetflixIcon" />
                <div>
                  <div className="flex items-center gap-3">
                  <p className="text-black300 font-bold opacity-60 text-sm">Disney+ Hotstar</p>
                  <div className="p-1 cursor-pointer flex items-center">
                    <i class="fa-solid text-sm fa-pen-nib"></i>
                    </div>
                  </div>
                  <p className="text-black300 font-normal opacity-60 text-sm">Your Disney subscription is expiring on 07 July</p>
                
                </div>
              </div>
              <div className="flex items-center gap-4 mobile:justify-end">
                <p className="text-sm font-medium text-black300">20/12/2024 01:43</p>
                <i class="fa-solid fa-trash text-red-600 cursor-pointer"></i>
              </div>
            </div>
          );
        })}
       </div>

      {/* <NotificationBody
        notifications={
          searchedNotifications.length > 0
            ? searchedNotifications
            : notifications
        }
      /> */}
    </>
  );
}

export default Notifications;
