import React, { useState } from "react";
import styles from "./profile.module.css";
import contactStyles from "../../contactus/contact.module.css";
import substyles from "../../subscription/sub.module.css";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import Appconstants from "../../../constants/AppConstants";
import axios from "axios";
import { message } from "antd";

function Profile() {
  const { userData, isUserSubscribed } = useAuth();
  const [loading, setLoading] = useState(false);
  const [buttontext, setbuttontext] = useState(isUserSubscribed);
  // console.log(userData, "userData");
  const navigate = useNavigate();
  const [profileState, SetProfileState] = useState({
    fullName: userData?.name ? userData?.name : "",
    company: "",
    email: userData?.email ? userData?.email : "",
    phone: "",
    password: "",
  });

  const handleSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (userData && isUserSubscribed) {
      // navigate("/dashboard/manage-subscription");
      try {
        // navigate("/https://buy.stripe.com/7sIdQRfTocj3dLa6oo"); // for live");
        // window.location.href = "https://buy.stripe.com/7sIdQRfTocj3dLa6oo"; // for live
        await fetch(`${Appconstants.baseURL}/subscription/cancelsubscription`, {
          method: "POST",
        })
          .then((res) => {
            console.log(res, "res in cancel");
            if (res.ok) {
              message.success(
                res?.data?.message
                  ? res?.data?.message
                  : "Subscription Canceled"
              );
              window.location.reload();
            } else {
              message.error(
                res?.data?.message ? res?.data?.message : "Something Went Wrong"
              );
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log(e.error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error, "error");
        setLoading(false);
      }
    } else {
      try {
        let price = 4.99;
        // navigate("/https://buy.stripe.com/7sIdQRfTocj3dLa6oo"); // for live");
        // window.location.href = "https://buy.stripe.com/7sIdQRfTocj3dLa6oo"; // for live
        await fetch(
          `${Appconstants.baseURL}/subscription/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            mode: "cors",
            body: JSON.stringify({ price: price }),
          }
        )
          .then((res) => {
            if (res.ok) return res.json();
            console.log(res);
            return res.json().then((json) => Promise.reject(json));
          })
          .then(({ session }) => {
            setLoading(false);
            window.location = session.url;
          })
          .catch((e) => {
            console.log(e.error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  return (
    <div>
      <div className=" pb-5 border-b border-solid border-borderColor1 ">
        <h2 className="text-xl text-black300 mobile:text-sm font-medium m-0">
          Basic Details
        </h2>
      </div>
      <div className="bg-white p-8 rounded-xl mt-5">
        <div className="w-24 relative">
          <div className="w-24 text-4xl text-black300 font-bold h-24 rounded-full primary-gradient-bg mb-4 flex items-center justify-center">
            P
          <div className="w-6 h-6 bg-white rounded-full shadow-lg absolute cursor-pointer right-0 bottom-2 flex items-center justify-center border border-solid border-borderColor1 z-10">
          <i class="fa-solid fa-pen-to-square text-[10px]"></i>
          </div>
        </div>
        </div>
        <form className="max-w-[700px]">
         <div className="grid grid-cols-2 gap-5">
         <div className="pb-4">
            <label htmlFor="Full_name" className="text-sm text-gray700 font-medium block pb-2">Full Name</label>
            <input
              name="fullName"
              type="text"
              value={profileState.fullName}
              id="Full_name"
              className="w-full border border-solid border-borderColor4 rounded h-9 placeholder:text-sm text-sm text-black300 bg-white"
              placeholder="Name"
              contentEditable={false}
              readOnly={true}
            />
          </div>
          <div className="pb-4">
            <label htmlFor="email" className="text-sm text-gray700 font-medium block pb-2">Email</label>
            <input
              name="email"
              type="email"
              value={profileState.email}
              id="email"
              className="w-full border border-solid border-borderColor4 rounded h-9 placeholder:text-sm text-sm text-black300 bg-white"
              placeholder="Email"
              contentEditable={false}
              readOnly={true}
            />
          </div>
         </div>
          {/* <div className="flex justify-end items-center">
            <button
              className="border-none cursor-pointer text-sm font-medium text-black300 py-3 px-6 rounded-md primary-gradient-bg"
              type="submit"
              disabled={loading}
              onClick={handleSubscription}
            >
              {buttontext ? "Cancel Subscription" : "Get Premium"}
            </button>
          </div> */}
        </form>
      </div>
      <div className="bg-white p-5 rounded-xl mt-5 flex items-center justify-between">
          <p className="text-xl font-bold text-black300">
          Delete your account
          </p>
          <button 
              className="border-none cursor-pointer text-sm font-medium text-white py-3 px-6 rounded-md bg-[#AE3628]"
          
          >
          Delete account
          </button>
      </div>

    </div>
  );
}

export default Profile;
