import React from 'react'

const InstagramIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <g clip-path="url(#clip0_1385_1327)">
        <path d="M9.99902 1.94034C12.669 1.94034 12.9857 1.95034 14.0399 1.99867C15.1299 2.04867 16.2524 2.29701 17.0465 3.09117C17.8482 3.89284 18.089 5.00451 18.139 6.09784C18.1874 7.15201 18.1974 7.46867 18.1974 10.1387C18.1974 12.8087 18.1874 13.1253 18.139 14.1795C18.0899 15.2637 17.8357 16.397 17.0465 17.1862C16.2449 17.9878 15.134 18.2287 14.0399 18.2787C12.9857 18.327 12.669 18.337 9.99902 18.337C7.32902 18.337 7.01236 18.327 5.95819 18.2787C4.88236 18.2295 3.73402 17.9695 2.95152 17.1862C2.15402 16.3887 1.90902 15.2662 1.85902 14.1795C1.81069 13.1253 1.80069 12.8087 1.80069 10.1387C1.80069 7.46867 1.81069 7.15201 1.85902 6.09784C1.90819 5.01784 2.16486 3.87784 2.95152 3.09117C3.75152 2.29117 4.86736 2.04867 5.95819 1.99867C7.01236 1.95034 7.32902 1.94034 9.99902 1.94034ZM9.99902 0.138672C7.28319 0.138672 6.94236 0.150339 5.87569 0.198672C4.32986 0.269505 2.79486 0.699505 1.67736 1.81701C0.55569 2.93867 0.129857 4.47034 0.0590234 6.01534C0.0106901 7.08201 -0.000976562 7.42284 -0.000976562 10.1387C-0.000976562 12.8545 0.0106901 13.1953 0.0590234 14.262C0.129857 15.8062 0.561523 17.3453 1.67736 18.4603C2.79819 19.5812 4.33236 20.0078 5.87569 20.0787C6.94236 20.127 7.28319 20.1387 9.99902 20.1387C12.7149 20.1387 13.0557 20.127 14.1224 20.0787C15.6674 20.0078 17.204 19.577 18.3207 18.4603C19.4432 17.3378 19.8682 15.807 19.939 14.262C19.9874 13.1953 19.999 12.8545 19.999 10.1387C19.999 7.42284 19.9874 7.08201 19.939 6.01534C19.8682 4.46951 19.4374 2.93367 18.3207 1.81701C17.2015 0.697838 15.6632 0.268672 14.1224 0.198672C13.0557 0.150339 12.7149 0.138672 9.99902 0.138672Z" fill="black" />
        <path d="M9.99926 5.00391C7.16342 5.00391 4.86426 7.30307 4.86426 10.1389C4.86426 12.9747 7.16342 15.2739 9.99926 15.2739C12.8351 15.2739 15.1343 12.9747 15.1343 10.1389C15.1343 7.30307 12.8351 5.00391 9.99926 5.00391ZM9.99926 13.4722C8.15842 13.4722 6.66592 11.9797 6.66592 10.1389C6.66592 8.29807 8.15842 6.80557 9.99926 6.80557C11.8401 6.80557 13.3326 8.29807 13.3326 10.1389C13.3326 11.9797 11.8401 13.4722 9.99926 13.4722Z" fill="black" />
        <path d="M15.3377 6.00059C16.0004 6.00059 16.5377 5.46333 16.5377 4.80059C16.5377 4.13784 16.0004 3.60059 15.3377 3.60059C14.675 3.60059 14.1377 4.13784 14.1377 4.80059C14.1377 5.46333 14.675 6.00059 15.3377 6.00059Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_1385_1327">
          <rect width="20" height="20" fill="white" transform="translate(0 0.139648)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InstagramIcon
