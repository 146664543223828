import React, { useEffect, useRef, useState } from "react";

// import { useRouter } from "next/router";
import _ from "lodash";
import styles from "./login.module.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import { useAuth } from "../../context/authContext";
import { EmailLoginSchema } from "../../utils/auth_validator.js";
import GoogleIcon from "@mui/icons-material/Google";
import AppConstants from "../../constants/AppConstants.js";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/logo.svg";
import EyeShow from "../../assets/images/eyeShow.png";
import EyeHidden from "../../assets/images/eyeHidden.png";

const Login = (props) => {
  // const router = useRouter();
  const navigate = useNavigate()
  const [showPass, setshowPass] = useState({
    createPass: true,
  });
  const [formErrors, setformErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState({
    email: "",
    password: "",
  });
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [passwordShow, setPasswordShow] = useState(false);

  console.log("inputValueeeeeeeee", inputValue);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const { login, errorMsg } = useAuth();
  const auth = useAuth();
  console.log(auth, "autjh");
  const handleGoogleSignIn = async () => {
    window.open(`${AppConstants.baseURL}/auth/google`, "_self");
    // console.log(window.location.href);
    // document.cookie = `location_to_redirect = ${
    //   window.location.href
    // }; expires=${24 * 60 * 60 * 1 * 1000}; path = /;`;
    // await router.push("https://alladdinx.com/api/auth/google");
    // await googleLogin();
  };

  const handleSubmit = async () => {
    if (validate(inputValue)) {
      setIsLoading(true);
      try {
        // let loginData = {};
        // loginData.email = inputValue?.email;
        // loginData.password = inputValue?.password;

        if (inputValue?.email && inputValue?.password) {
          let res = await login(inputValue);
          if (res?.code) {
            setIsLoading(false);
            setInputValue({});
          } else {
            setIsLoading(false);
            setInputValue({ email: "", password: "" });
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
  };

  const validate = (values) => {
    let errors = {};
    let isValid = true;
    const emailreRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email id is required!";
      isValid = false;
    } else if (values.email && !emailreRegex.test(values.email)) {
      errors.email = "Email is in incorrect format";
      isValid = false;
    }
    if (!values.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (values.password && values.password.length <= 5) {
      message.error("Invalid email or password");
      isValid = false;
    }
    setErrMessages(errors);
    return isValid;
  };

  const handleChange = (e) => {
    // const { name, value } = e.target;
    // const trimmedValue = value.trimStart();
    // if (value !== trimmedValue) {
    //   setInputValue({ ...inputValue, [name]: trimmedValue });
    // } else {
    //   if(name === "email"){
    //     setInputValue({ ...inputValue, [name]: value.toLowerCase() });
    //   }
    //   else{
    //     setInputValue({ ...inputValue, [name]: value });
    //   }
    //   setErrMessages({ ...errMessages, [name]: "" });
    // }
    if (e.target.value.trim() !== "") {
      setInputValue({ ...inputValue, [e.target.name]: e.target.name === "email" ? e?.target?.value?.trim()?.toLowerCase() : e?.target?.value?.trim() });
      setErrMessages({ ...errMessages, [e.target.name]: "" });
    } else {
      setInputValue({ ...inputValue, [e.target.name]: "" });
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = (e.clipboardData || window.clipboardData).getData("text").trim();
    const input = paste;
    return input;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("text").trim();
    const input = data;
  };

  useEffect(() => {
    document.body.style.overflow = "";
  }, []);

  return (
    <>
      {/*---------- modal body when loginwithemailandpass = true -------- */}
      {/* <div className={styles.main_parent_div}>
        <div className={styles.main}>
          <div className={styles.child}>
            <div className={styles.heading}>
              <div className={styles.para}>
                <p>
                  Simplify Your
                  <span id={styles.subs}> Subscriptions,</span>
                  <br />
                  Amplify Your Savings
                </p>
              </div>
              <div className={styles.imageLogoContainer}>
                <img src="/assets/alladdin_logo.png" alt="brandmark" />
              </div>
            </div>
            <div className={styles.details}>
              <div class={styles.details_child}>
                <div className={styles.signIn}>
                  <p>Sign-in</p>
                </div>
                <div>
                  <form className="modal_body_form" onSubmit={handleSubmit}>
                    <div
                      className={styles.input_field}
                      controlId="formBasicEmail"
                    >
                      <label>Enter Email, Phone No.</label>
                      <input
                        className="modalInp"
                        type="text"
                        placeholder="Enter your email"
                        ref={emailInputRef}
                        name="email"
                        required
                        autoComplete="false"
                        style={{ height: "36px" }}
                      />
                    </div>

                    <div
                      className={styles.input_field}
                      controlId="formBasicPassword"
                    >
                      <div className="label_wrapper">
                        <label>Password</label>
                      </div>
                      <div className={styles.inputwrapper}>
                        <div className={styles.input_groups}>
                          <input
                            style={{ height: "36px" }}
                            name="password"
                            required
                            type={showPass.createPass ? "password" : "text"}
                            className="form-control password_input"
                            placeholder="Enter your password"
                            ref={passwordInputRef}
                            autoComplete="false"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn eye_btn"
                              type="button"
                              onClick={() =>
                                setshowPass({
                                  ...showPass,
                                  createPass: !showPass.createPass,
                                })
                              }
                              style={{ border: "none", background: "none" }}
                            >
                              {showPass.createPass ? (
                                <RemoveRedEyeIcon style={{ color: "#000" }} />
                              ) : (
                                <VisibilityOffIcon style={{ color: "#000" }} />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.btn_grps}>
                      <div className={styles.w_50}>
                        <button
                          className={styles.Login_btn}
                          variant="primary"
                          type="submit"
                          size="lg"
                        >
                          Log in
                        </button>
                      </div>
                      <div className={styles.w_50}>
                        <button
                          // variant="outline-dark"
                          className={styles.google_btn}
                          type="button"
                          onClick={() => handleGoogleSignIn()}
                        >
                          <GoogleIcon
                            style={{ marginRight: "10px", color: "#d0ee11" }}
                          />
                          Google Login
                        </button>
                      </div>
                    </div>
                    <div className={styles.create_acc}>
                      <p className={styles.signUp_text}>
                        Don’t have an account?&nbsp;
                        <Link
                          to="/signup"
                          style={{
                            color: "#3E6EDC",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          Create now
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="h-screen bg-greenLight tab:h-auto">
        <div className="p-14 h-full mobile:py-0 mobile:px-0 mobile:h-auto">
          <div className="max-w-1340 h-full mx-auto px-5 mobile:px-0">
            <div className="bg-primary h-full rounded-[20px] mobile:h-auto mobile:pb-60 mobile:rounded-none">
              <div className="grid h-full grid-cols-2 tab:grid-cols-1 gap-100 mobile:gap-0 mobile:pt-60">
                <div className="h-full p-16 mobile:p-5 flex items-center">
                  <div>
                    <div className="pb-16 mobile:pb-10 mobile:flex mobile:items-center mobile:justify-center">
                      <NavLink to="/">
                        <img className="cursor-pointer w-[320px] mobile:w-auto" src={Logo} alt="Logo" />
                      </NavLink>
                    </div>
                    <h2 className="text-[50px] mobile:text-[32px] mobile:text-center mobile:mb-8 font-normal text-black300 mb-60">
                      Simplify Your <span className="font-extrabold">Subscriptions</span>, Amplify Your Savings
                    </h2>
                  </div>
                </div>
                <div className="h-full bg-white rounded-[20px] flex items-center mobile:p-5 p-10 mobile:m-5">
                  <div className="w-full">
                    <h2 className="text-[30px] mb-10 text-black300 font-bold tracking-[1.4px]">Sign-in</h2>
                    <div className="pb-5">
                      <label className="block text-base text-gray600 pb-4">
                        Email<span className="text-redError">*</span> <span className="text-redError text-sm">{errMessages?.email}</span>
                      </label>
                      <input
                        className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent"
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={inputValue?.email}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="pb-5">
                      <label className="block text-base text-gray600 pb-4">
                        Password<span className="text-redError">*</span> <span className="text-redError text-sm">{errMessages?.password}</span>
                      </label>
                      <div className="relative flex items-center">
                        <input
                          className="px-4 w-full h-[50px] border focus:border-primary border-solid text-base placeholder:text-base border-borderColor1 bg-transparent flex"
                          type={passwordShow ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={inputValue?.password}
                          onChange={(e) => handleChange(e)}
                          style={{ padding: "0 40px 0 10px" }}
                        />
                        {passwordShow ? (
                          <img
                            className="absolute right-[10px] top-1/2 translate-y-1/2 cursor-pointer"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            src={EyeShow}
                            alt="EyeShow"
                            width={20}
                            height={20}
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        ) : (
                          <img
                            className="absolute right-[10px] top-1/2 translate-y-1/2 cursor-pointer"
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                            src={EyeHidden}
                            alt="EyeHidden"
                            width={20}
                            height={20}
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        )}
                      </div>

                      {/* <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
                        <input style={{ flex: 1, padding: "0 40px 0 10px" }} type="password" placeholder="Password" name="password" value={inputValue?.password} onChange={(e) => handleChange(e)} onPaste={handlePaste} onDrop={handleDrop} />
                        <img
                          src={EyeShow}
                          alt="EyeShow"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          width={20}
                          height={20}
                        />
                      </div> */}
                    </div>

                    <div className="flex justify-center w-full">
                      <span className="cursor-pointer" onClick={() => navigate("/forgot-password")}>Forgotten password?</span>
                    </div>

                    <div className="flex items-center justify-center pt-4 pb-3">
                      <button disabled={isLoading} className="flex justify-center gap-2.5 p-3 px-9 rounded-full text-base font-semibold bg-primary w-full" onClick={() => handleSubmit()}>
                        Login {isLoading && <div className="loader"></div>}
                      </button>
                    </div>
                    <p className="text-base font-medium text-gray600 mb-3 text-center">OR</p>
                    <button className=" p-3 px-9 flex items-center gap-3 justify-center rounded-full text-base font-semibold bg-white border border-solid border-primary w-full" onClick={() => handleGoogleSignIn()}>
                      <GoogleIcon style={{ color: "#d0ee11" }} />
                      Google Login
                    </button>
                    <p className="text-base pt-5 font-medium text-black300 text-center">
                      Don’t have an account?{" "}
                      <Link to="/signup">
                        <a className="text-link">Signup Here</a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*---------- modal footer when loginwithemailandpass = true -------- */}
    </>
  );
};

export default Login;
