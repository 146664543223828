import React, { useEffect } from "react";
import styles from "./subscription.module.css";
import SubscriprionCards from "../../common/commonComponents/SubscriptionCards";
import { useDispatch, useSelector } from "react-redux";
import { GetALLSubscriptions, GetBrowseAllData } from "../../../redux/features/subscriptionSlice";
import { currentSubscriptionList } from "../../../redux/features/subscriptionSlice";
import SubscriptionSlider from "../../../modules/adminDashboard/currentSubscription/subscriptionSlider";
import moment from "moment";
import TimeIcon from '../../../assets/icons/time-icon.svg';
import { useNavigate } from "react-router";

function BrowserAlladdinCards({item}) {
  let daysLeft = item?.end_date ? Math.floor((new Date(item.end_date) - new Date()) / (1000 * 60 * 60 * 24)) : "0";
  const navigate = useNavigate()
  return (
    <>
    <div className=' bg-white rounded-2xl'>
    <div className='py-6 flex items-center justify-center'>
        <img
            className='w-[60px] h-[60px] block rounded-2xl'
            src={item?.sub_logos} alt='HuluIcon' />
    </div>
    <div className='px-4 pb-6'>
    <p className='text-lg text-black font-bold'>
            ${item?.cost?.toFixed(2) ?? 0.00} <span className='text-sm font-normal'>/Mo</span>
        </p>
        
    <span className='text-sm text-[#777] font-normal add-new-card-description min-h-[60px]'>
            {item?.sub_descriptions}
        </span>

        {/* <p className='text-[12px] font-bold text-black300 tracking-normal mb-1'>
            Pay date: <span className='font-normal'>6th July 2023</span>
        </p>
        <div className='p-1 rounded bg-lightgray19 flex items-center justify-between'>
            <span className='block text-xs font-normal text-black'>
                5 Days remaining
            </span>
            <img src={TimeIcon} alt='TimeIcon' className='block' />
        </div> */}
    </div>
    <div className='primary-gradient-bg p-2 mobile:p-2 flex items-center justify-center rounded-b-[16px] cursor-pointer' 
    onClick={() => navigate(`/dashboard/details/${item?.sub_names?.split(" ").join("")}/${item?.sub_ids}`)}
    // onClick={() => handleOpenAddModel(item)}
    >
        <p className='text-sm font-normal text-black300 text-center cursor-pointer'>
            Add {item?.sub_names}
        </p>
    </div>
</div>
     {/* <div className=" bg-white rounded-2xl">
      <div className="py-6 flex items-center justify-center">
        <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logos} alt="HuluIcon" />
      </div>
      <div className="px-4 pb-6">
        <p className="text-[12px] font-bold text-black300 tracking-normal mb-1">
          Pay date: <span className="font-normal"> {item?.end_date ? moment(item.end_date).format("Do MMM YYYY") : "-"}</span>
        </p>
        <div className="p-1 rounded bg-lightgray19 flex items-center justify-between">
          <span className="block text-xs font-normal text-black">{daysLeft >= 0 ? daysLeft + " Days remaining" : "Already Paid"}</span>
          <img src={TimeIcon} alt="TimeIcon" className="block" />
        </div>
        <p className="mt-2 text-lg text-black font-bold">
          ${item?.cost ?? 0} <span className="text-sm font-normal">{daysLeft >= 0 ? "to be paid" : "paid"}</span>
        </p>
      </div>
      <div className="primary-gradient-bg p-2.5 flex items-center justify-center rounded-b-[16px]">
        <p className="text-sm font-normal text-black300 text-center cursor-pointer">Access {item?.sub_names}</p>
      </div>
    </div> */}
    </>
  );
}

export default BrowserAlladdinCards;
