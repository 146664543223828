import axios from "axios";
import AppConstants from "../../constants/AppConstants";
const API = axios.create({ baseURL: AppConstants.baseURL });
const header = {
  "Content-Type": "application/json",
  "x-auth-token": localStorage.getItem("authToken"),
  "ngrok-skip-browser-warning": "69420",
};

// get routes

export const getUserSubscriptions = (data) => API.get("/subscription/usersubsriptions", data);

export const getTopSubscriptions = () => API.get("/subscription/topsubscriptions");
// export const getBrowseAlladdin = (data) => axios.get(`https://dev.alladdinx.com/api/subscription/getCatSub?categoryId=${data ?? ''}`, null, { headers: header});
// export const getAllCategoryAll = (data) => axios.get("https://dev.alladdinx.com/api/category/getCategory", data, { headers: header});
export const getBrowseAlladdin = (data) => API.get(`/subscription/getCatSub?categoryId=${data ?? ''}`, null, { headers: header});
export const getAllCategoryAll = (data) => API.get("/category/getCategory", data, { headers: header});

// post routes
export const getNotifications = (data) => API.post("/subscription/notifications", data);

export const getRecommendedSubscriptions = () => API.post("/subscription/getrecommendedsubscriptions");

export const addSubscription = (data) =>
  API.post("/subscription/addsubscription", data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("authToken"),
      "ngrok-skip-browser-warning": "69420",
    },
  });
export const updateSubscriptionApi = (data) =>
  API.put(`/subscription/updateUserSubDetails?id=${data?.id}`, data?.body, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("authToken"),
    },
  });
export const deleteSubscriptionApi = (data) =>
  API.delete(`/subscription/deleteUserSubDetails?id=${data?.id}`, {
    headers: header,
  });
export const deleteTransactionApi = (data) =>
  API.delete(`/subscription/deleteUserSubHistory?id=${data?.id}&isDeleted=1`, {
    headers: header,
  });
export const subscriptionStatusApi = (data) =>
  API.put(`/subscription/updateUserSubStatus?id=${data?.id}`, data?.body, {
    headers: header,
  });
export const sendMailToUser = (data) => API.post("/subscription/sendmailtouser", data);
export const addCurrentSubscriptionApi = (data) =>
  API.post("/subscription/currentsubscription", data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("authToken"),
      "ngrok-skip-browser-warning": "69420",
    },
  });
export const activityStreamGraphAPI = (data) =>
  API.post("/subscription/subscriptiongraph", data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("authToken"),
      "ngrok-skip-browser-warning": "69420",
    },
  });
export const getAllSubscriptions = () => API.post("/subscription/allsubscription");
export const getCurrentUsersSubscriptions = (data) =>
  API.get(`/subscription/getUserSubDetails?active=${data?.status ?? true}&categorie=${data?.category ?? ''}`, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("authToken"),
      "ngrok-skip-browser-warning": "69420",
    },
  });
export const getUserCurrentCategoryAPI = (data) =>
  API.get(`/subscription/getUserSubCat?active=${data}`, { headers: {
    "Content-Type": "application/json",
    "x-auth-token": localStorage.getItem("authToken"),
    "ngrok-skip-browser-warning": "69420",
  },});
export const getAllCurrentSubscriptions = (data) =>
  API.post(`/subscription/currentsubscription?active=${data?.status ?? true}`, {}, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("authToken"),
      "ngrok-skip-browser-warning": "69420",
    },
  });
export const getAllUpcomingSubscription = (data) =>
  API.post("/subscription/recurringsubscriptions", data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("authToken"),
      "ngrok-skip-browser-warning": "69420",
    },
  });

export const getAvrageCoasts = () =>
  API.get("/subscription/averageCost", {
    headers: header,
  });

export const userSpendingDataApi = (data) => API.post("/subscription/userspending", data, { headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("authToken"),
      "ngrok-skip-browser-warning": "69420",
    }});

export const recurringSubscriptionsApi = (data) => API.post("/subscription/recurringsubscriptions", data);

export const getCalenderDataApi = (data) => API.post("/subscription/calendersubscription", data, { headers: header});

export const allTransactionsApi = (data) => API.post(`/subscription/alltransactions?search=${data?.query ?? ''}`, data?.body , { headers: header});

export const allSubscriptionAmountsGraphApi = (data) => API.post("/subscription/subscriptiongraph", data);

export const removeNotificationApi = (data) => API.post("/subscription/removenotification", data);

export const getSubsDetails = (data) => API.post("/subscription/subdetials", data);
export const getsubsgraphs = (data) => API.post("/subscription/subsgraphs", data);
