import React from "react";
import HuluIcon from "../../assets/icons/hulu.svg";
import TimeIcon from "../../assets/icons/time-icon.svg";
import { useNavigate } from "react-router";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
const AddNewSubsCard = ({ item, index, handleOpenAddModel }) => {
  const navigate = useNavigate();
  const { borwseAlladdinLoader } = useSelector((state) => state.subscription);

  return (
    // <div className=" bg-white rounded-2xl" >
    //   <div className="py-6 flex items-center justify-center" onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
    //     <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
    //   </div>
    //   <div className="px-4 pb-6" onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
    //     <p className="text-lg text-black font-bold">
    //       ${item?.cost?.toFixed(2)} <span className="text-sm font-normal">/Mo</span>
    //     </p>

    //     <span className="text-sm text-[#777] font-normal add-new-card-description min-h-[60px]">{item?.sub_description}</span>

    //     {/* <p className='text-[12px] font-bold text-black300 tracking-normal mb-1'>
    //         Pay date: <span className='font-normal'>6th July 2023</span>
    //     </p>
    //     <div className='p-1 rounded bg-lightgray19 flex items-center justify-between'>
    //         <span className='block text-xs font-normal text-black'>
    //             5 Days remaining
    //         </span>
    //         <img src={TimeIcon} alt='TimeIcon' className='block' />
    //     </div> */}
    //   </div>
    //   <div
    //     className="primary-gradient-bg p-2.5 flex items-center justify-center rounded-b-[16px]"
    //     // onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}
    //     onClick={() => handleOpenAddModel(item)}
    //   >
    //     <p className="text-sm font-normal text-black300 text-center cursor-pointer">Add {item?.sub_name}</p>
    //   </div>
    // </div>

    borwseAlladdinLoader ? (
      <>
        <div className="bg-white min-w-[240px] max-w-[240px] w-[240px] rounded-2xl flex flex-col justify-between">
        <div>
          <div className="pt-3 py-3 flex items-center justify-center">
            <Skeleton circle width={60} height={60} />
          </div>
          <div className="px-4 pb-3">
            <div className="w-full flex justify-center">
            <Skeleton height={20} width={120} />
            </div>
            <div className="flex justify-center pb-3">
              <Skeleton width={50} height={20} className="mr-1" />
              <Skeleton width={50} height={20} className="mr-1" />
              <Skeleton width={50} height={20} />
            </div>
            <Skeleton height={80} width={200} />
          </div>
        </div>
        <div className="bg-lightgray19 p-2 mobile:p-2 flex items-center justify-center rounded-b-[16px] cursor-pointer">
          <Skeleton height={20} width={100} />
        </div>
      </div>
      </>
    ) : (
      <div className=" bg-white min-w-[240px] max-w-[240px] w-[240px] rounded-2xl flex flex-col justify-between">
        <div>
          <div className="pt-3 py-3 flex items-center justify-center" onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
            <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
          </div>
          <div className="px-4 pb-3" onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
            <p className="text-center text-sm text-black200 font-medium">{item?.sub_name}</p>
            {/* <span className="text-xs font-normal text-gray600 block text-center pb-2">Education</span> */}
            <div className="flex justify-center pb-3">
              {item?.categories?.length === 1 && (
                <div className="border-solid px-1 border-r border-borderColor1 border-none">
                  <p className="text-[10px] text-center leading-3 font-medium text-black">{item?.categories?.[0]}</p>
                </div>
              )}

              {item?.categories?.length === 2 && (
                <>
                  <div className="border-solid pr-1 border-r border-borderColor1">
                    <p className="text-[10px] text-center leading-3 font-medium text-black">{item?.categories?.[1]}</p>
                  </div>

                  <div className="border-solid px-1 border-r border-borderColor1 border-none">
                    <p className="text-[10px] text-center leading-3 font-medium text-black">{item?.categories?.[0]}</p>
                  </div>
                </>
              )}

              {item?.categories?.length === 3 && (
                <>
                  {" "}
                  <div className="border-solid pr-1 border-r border-borderColor1">
                    <p className="text-[10px] text-center leading-3 font-medium text-black">{item?.categories?.[1]}</p>
                  </div>
                  <div className="border-solid px-1 border-r border-borderColor1">
                    <p className="text-[10px] text-center leading-3 font-medium text-black">{item?.categories?.[0]}</p>
                  </div>
                  <div className="pl-1">
                    <p className="text-[10px] text-center leading-3 font-medium text-black">{item?.categories?.[2]}</p>
                  </div>
                </>
              )}
            </div>
            <p className="text-sm font-normal text-center text-black text-ellipsis">{item?.sub_description}</p>
          </div>
        </div>
        <div className="primary-gradient-bg p-2 mobile:p-2 flex items-center justify-center rounded-b-[16px] cursor-pointer" onClick={() => handleOpenAddModel(item)}>
          <p className="text-sm font-normal text-black300 text-center cursor-pointer">Add {item?.sub_name}</p>
        </div>
      </div>
    )
  );
};

export default AddNewSubsCard;
