import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import "./App.css";
import MainRoutes from "./routing/MainRoutes";
import { AuthProvider, getUser } from "./context/authContext";
import Loader from "react-js-loader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const App = React.memo(() => {
  const [auth, setAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const authData = await getUser();
      console.log("authDataaaaaaaaa", authData)
      setAuth(authData);
      setLoading(false);
    }
    fetchData();
  }, []);

  return isLoading ? (
    <div className="row">
      {/* <div className="item">
        <Loader
          type="spinner-circle"
          bgColor={"#000"}
          title={"Loading"}
          color={"#000"}
          size={100}
        />
      </div> */}
    </div>
  ) : auth ? (
    <AuthProvider myAuth={auth}>
      <MainRoutes />
    </AuthProvider>
  ) : (
    <Navigate to="/login" replace />
  );
});

export default App;



