import React from 'react'

function DeleteSubscriptionModel({deleteModel, setDeleteModel, handleDeleteSubscription}) {
  return (
    <div className="global-modal" style={{ zIndex: "999" }}>
      <div className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:h-[calc(100vh-150px)] mobile:overflow-auto">
        {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
        <div className="flex justify-between items-center">
          <p className="text-lg text-black300 font-medium text-center mb-5">Are you sure want to delete this subscription?</p>
        </div>

        <div className="flex justify-end gap-3 pt-3">
          <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground" onClick={() => {setDeleteModel({isShow: false, id: null})}}>
            Cancel
          </button>
        <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleDeleteSubscription()}>
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteSubscriptionModel