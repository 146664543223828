import moment from "moment";
import React from "react";
import TimeIcon from "../../../../assets/icons/time-icon.svg";
import FreeIcon from "../../../../assets/icons/free.svg";
import FreeIconExpired from "../../../../assets/icons/freeExpire.svg";
import { useNavigate } from "react-router";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

function SubscriptionSlider({ item, handleOpenModel, activeModel, setActiveModel }) {
  let daysLeft = item?.end_date ? Math.floor((new Date(item.end_date) - new Date()) / (1000 * 60 * 60 * 24)) : "0";
  const { currentAllSubsLoader } = useSelector((state) => state.subscription);
  const navigate = useNavigate();

  return (
    <>
      {currentAllSubsLoader ? (
        <>
          <div className="bg-white rounded-2xl">
            <div className="pt-3 py-3 flex items-center justify-center">
              <Skeleton borderRadius={15} width={60} height={60} />
            </div>
            <div className="px-4 pb-3 min-h-[178px]">
              <div className="w-full flex justify-center">
                <Skeleton height={20} width={120} />
              </div>
              <div className="w-full flex justify-center">
                <Skeleton height={15} width={80} className="mt-1" />
              </div>
              <Skeleton height={15} width={150} className="mt-1" />
              <div className="p-1 rounded bg-lightgray19 flex items-center justify-between">
                <Skeleton height={14} width={100} />
                <Skeleton width={12} height={12} />
              </div>
              <div className="pt-2">
                <div className="flex items-center gap-0.5">
                  <Skeleton height={20} width={60} />
                  <Skeleton height={20} width={80} className="ml-2" />
                </div>
                <div className="flex pt-2 items-center gap-3">
                  <Skeleton height={18} width={50} />
                  <Skeleton height={18} width={100} />
                </div>
              </div>
            </div>
            <div className="bg-lightgray19 p-2 w-full mobile:p-2 flex items-center justify-center rounded-b-[16px] cursor-pointer">
              <Skeleton height={20} width={120} />
            </div>
          </div>
        </>
      ) : item?.type === "free" ? (
        <div className=" bg-white rounded-2xl">
          <div className="pt-3 py-3 flex items-center justify-center" onClick={() => handleOpenModel(item)}>
            <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
          </div>
          <div className="px-4 pb-3 min-h-[178px]" onClick={() => handleOpenModel(item)}>
            <p className="text-center text-sm text-black200 font-medium">{item?.sub_name}</p>
            {/* <span className="text-xs font-normal text-gray600 block text-center pb-2">{item?.categories[0]}</span> */}

            <div className="flex justify-center pb-3">
              {item?.categories?.length === 1 && (
                <div className="border-solid px-1 border-r border-borderColor1 border-none">
                  <span className="text-xs font-normal text-gray600 block text-center">{item?.categories?.[0]}</span>
                </div>
              )}

              {item?.categories?.length === 2 && (
                <>
                  <div className="border-solid pr-1 border-r border-borderColor1">
                    <span className="text-xs font-normal text-gray600 block text-center">{item?.categories?.[1]}</span>
                  </div>

                  <div className="border-solid px-1 border-r border-borderColor1 border-none">
                    <span className="text-xs font-normal text-gray600 block text-center">{item?.categories?.[0]}</span>
                  </div>
                </>
              )}

              {item?.categories?.length === 3 && (
                <>
                  {" "}
                  <div className="border-solid pr-1 border-r border-borderColor1">
                    <span className="text-xs font-normal text-gray600 block text-center">{item?.categories?.[1]}</span>
                  </div>
                  <div className="border-solid px-1 border-r border-borderColor1">
                    <span className="text-xs font-normal text-gray600 block text-center">{item?.categories?.[0]}</span>
                  </div>
                  <div className="pl-1">
                    <span className="text-xs font-normal text-gray600 block text-center">{item?.categories?.[2]}</span>
                  </div>
                </>
              )}
            </div>

            <p className="text-[12px] font-bold text-black300 tracking-normal mb-1">
              Start Date: <span className="font-normal"> {item?.start_date ? moment(item.start_date).format("Do MMM YYYY") : "-"}</span>
            </p>
            <div className="p-1 rounded bg-lightgray19 flex items-center justify-between">
              <span className="block text-xs font-normal text-black">{daysLeft >= 0 ? daysLeft + " Days remaining" : "Expired"}</span>
              <img width={12} height={12} src={TimeIcon} alt="TimeIcon" className="block cursor-pointer" />
            </div>
            <span className="block text-xs font-normal text-gray700 pt-1">{item?.isPaymentMethod === 0 ? "Payment Not Added" : " Payment Added"}</span>
            <div className="flex items-center gap-3 py-2">
              {daysLeft >= 0 ? <img src={FreeIcon} alt="FreeIcon" /> : <img src={FreeIconExpired} alt="FreeIcon" />}
              <p className="text-sm font-semibold text-black200">{item?.free_trial_days} Days</p>
            </div>
            <div className="flex items-center gap-3">
              <p className="text-sm font-semibold text-black200">End Date:</p>
              <p className="text-xs font-medium text-black200">{item?.end_date ? moment(item.end_date).format("Do MMM YYYY") : "-"}</p>
            </div>
          </div>
          <a href={item?.plan_link} target="_blank">
            <div className="bg-[#46aefc] p-2.5 flex items-center justify-center rounded-b-[16px]">
              <p className="text-sm font-normal text-black300 text-center cursor-pointer">Upgrade</p>
            </div>
          </a>
        </div>
      ) : (
        <div className=" bg-white rounded-2xl">
          <div className="pt-3 py-3 flex items-center justify-center" onClick={() => handleOpenModel(item)}>
            <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
          </div>
          <div className="px-4 pb-3 min-h-[178px]" onClick={() => handleOpenModel(item)}>
            <p className="text-center text-sm text-black200 font-medium">{item?.sub_name}</p>
            <span className="text-xs font-normal text-gray600 block text-center pb-2">{item?.categories[0]}</span>
            <p className="text-[12px] font-bold text-black300 tracking-normal mb-1">
              Next Renewal: <span className="font-normal"> {item?.end_date ? moment(item.end_date).format("Do MMM YYYY") : "-"}</span>
            </p>
            <div className="p-1 rounded bg-lightgray19 flex items-center justify-between">
              <span className="block text-xs font-normal text-black">{daysLeft >= 0 ? daysLeft + " Days remaining" : "Already Paid"}</span>
              <img src={TimeIcon} width={12} height={12} alt="TimeIcon" className="block cursor-pointer" />
            </div>
            <div className=" pt-2">
              <div className="flex items-center gap-0.5">
                <div className="bg-[#f5f5f5] d-flex items-center justify-center py-2 px-3 rounded max-w-max">
                  <p className="text-base font-semibold text-black200">${item?.cost ?? 0}</p>
                </div>
                <span className="text-black font-medium upper capitalize"> {item?.plan_name}</span>
              </div>
              <div className="flex pt-2 items-center gap-3">
                <p className="text-sm font-semibold text-black200">Plan:</p>
                <p className="text-xs font-medium text-black200 truncate">{item?.plan_variety}</p>
              </div>
            </div>
          </div>
          {item?.active ? (
            <div className="primary-gradient-bg p-2 mobile:p-2 flex items-center justify-center rounded-b-[16px] cursor-pointer" onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
              <p className="text-sm font-normal text-black300 text-center cursor-pointer">Manage</p>
            </div>
          ) : (
            <div
              className="bg-[#fe3c3cd4] p-2 mobile:p-2 flex items-center justify-center rounded-b-[16px] cursor-pointer"
              onClick={() => {
                setActiveModel({ isShow: true, id: item?.id, isActive: item?.active === 0 ? true : false });
              }}
            >
              <p className="text-sm font-normal text-black300 text-center cursor-pointer">Reactivate</p>
            </div>
          )}
        </div>
      )}

      {/* <div className=" bg-white rounded-2xl">
        <div className="pt-3 py-3 flex items-center justify-center">
          <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
        </div>
        <div className="px-4 pb-3">
          <p className="text-center text-sm text-black200 font-medium">
            Master Classes
          </p>
          <span className="text-xs font-normal text-gray600 block text-center pb-2">Education</span>
          <div className="flex justify-between pb-3">
            <div className="border-solid pr-1 border-r border-borderColor1">
              <p className="text-[10px] text-center leading-3 font-medium text-black">
                Free Trial
              </p>
            </div>
            <div className="border-solid px-1 border-r border-borderColor1">
              <p className="text-[10px] text-center leading-3 font-medium text-black">
                4K Video
              </p>
            </div>
            <div className="pl-1">
              <p className="text-[10px] text-center leading-3 font-medium text-black">
                Value for
                Money
              </p>
            </div>
          </div>
          <p className="text-xs font-normal text-center text-black">
            Netflix is a global streaming
            service offering diverse
            shows, movies, and originals
            across multiple genres.
          </p>

        </div>
        <div className="primary-gradient-bg p-2.5 flex items-center justify-center rounded-b-[16px] cursor-pointer">
          <p className="text-sm font-normal text-black300 text-center cursor-pointer">Access {item?.sub_name}</p>
        </div>
      </div> */}
    </>
  );
}

export default SubscriptionSlider;
